import React, { useState, useEffect } from 'react'
import VendingMetPayments from './VendingMetPayments'
import { Modal } from 'react-bootstrap'
//Utils
import Empty from '../../utils/Empty';
import { TbRuler } from 'react-icons/tb';
import oledComunication from './OledComunication';

function VendingModalCart({ setShoppingCart, shoppingCart, showModalShopingCart, setShowModalShopingCart, eventHandler, dataClient, setDataClient, showModalPayment, setShowModalPayment, setEventHandlerMenu, setStatusScreen, statusComunicationVending, groups, orderNumber, setOrderNumber, payments, setPayments, oledScreenPayments, setAtivityTimer }) {

  let machine = JSON.parse(localStorage.getItem('machine_vending'));
  
  //Totals states 
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
  const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
  const [invoiceTotalDiscount, setInvoiceTotalDiscount] = useState(0);
  const [taxesArrayAll, setTaxesArrayAll] = useState([])
  //Event handler shopping cart
  const [eventHandlerShoppingCart, setEventHandlerShoppingCart] = useState(0);
  const [eventHandlerAll, setEventHandlerAll] = useState(0);

  //decimal reduction
  const decimalReduction = (value) => {
        
    let string = value.toString()
    let arrayString = string.split(".");
    let newDecimalString = []
    let sample =''
    if(arrayString[1] !=undefined){
      newDecimalString = arrayString[1].slice(0, 2);
      sample =arrayString[0] + "." + newDecimalString
      console.log("Hice la reduccion");
    }else{
      console.log("Array: " + arrayString);
      console.log("Decimal: " + newDecimalString);
      console.log("Nuevo valor: "+ sample);
      sample = string;
    }

    let endValue = parseFloat(sample)
    return endValue
}

  //Function to calculate the invoice totals 
  const calculateInvoiceTotals = (products) => {
    //For every new calculate we set the totals 
    let invoiceTotalDiscount = 0
    let invoiceSubtotal = 0
    let invoiceTotalItbms = 0
    let invoiceTotal = 0
    let taxesArray = [];
    //if we dont we products we put the totals in 0 for default 
    if (products.length === 0) {
      //We calculate the totals of the invoice 
      setInvoiceTotalDiscount(0)
      setInvoiceSubtotal(0);
      setInvoiceTotalItbms(0)
      setInvoiceTotal(0);
      setTaxesArrayAll(taxesArray);
    } else {
      //For every product we calculate 
      for (let i = 0; i < products.length; i++) {
        let product_info = products[i]

        //Variables
        let total_product_price = 0;
        let total_product_itbms = 0;
        let product_total_discount = 0;
        //We set the total discount of the product
        product_total_discount = (product_info.unit_discount * product_info.amount);
        //We set the total of the product 
        total_product_price = (product_info.amount * (product_info.price - product_info.unit_discount));

        //Calculate tax by group--------------------------------
        let taxesGroup = product_info.taxes;
        taxesGroup.forEach(tax => {

          //calculate tax  value and add it to array

          let valueTax = decimalReduction((product_info.amount * ((product_info.price - product_info.unit_discount) * tax.taxtValue)));
          tax.total = valueTax;
          //Identifier tax of the group position
          var index = taxesArray.findIndex(function (info, i) {
            return info.tax_id === tax.tax_id
          });

          if (index < 0) {
            let taxInfoNow = {
              "dTasaITBMSValue": tax.dTasaITBMSValue,
              "percentage": tax.percentage,
              "tax": tax.tax,
              "tax_id": tax.tax_id,
              "taxtValue": tax.taxtValue,
              "total": valueTax
            }
            taxesArray.push(taxInfoNow);
          } else {
            taxesArray[index].total = (taxesArray[index].total + valueTax)
          }

          total_product_itbms = (total_product_itbms + valueTax);
        });
        //-------------------------------------------------------


        //We set the itbms of the product 
        //total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * product_info.taxtValue));

        //We calculate the totals of the invoice 
        invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
        invoiceSubtotal = decimalReduction((invoiceSubtotal + total_product_price));
        invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
        invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;

        console.log("invoiceTotalDiscount:" + invoiceTotalDiscount);
        console.log("invoiceSubtotal:" + invoiceSubtotal);
        console.log("invoiceTotalItbms:" + invoiceTotalItbms);
        console.log("invoiceTotal:" + invoiceTotal);
      }

      //We set the totals
      setInvoiceTotalDiscount(invoiceTotalDiscount)
      setInvoiceSubtotal(invoiceSubtotal);
      setInvoiceTotalItbms(invoiceTotalItbms);
      setInvoiceTotal(invoiceTotal);
      setTaxesArrayAll(taxesArray);
    }


   
  }

  //Function to incremet the product amount on the shopping cart 
  const decrementProductAmount = (product_index) => {
    //We search the product on the list 
    let product = shoppingCart[product_index];
    //We affect the product data 
    product.amount = product.amount - 1;
    product.cantidadItem = product.amount

    //We check if the product amount is lower than 0
    if (product.amount <= 0) {
      //if is lower or equal to 0 we delete the product of the shopping cart list 
      shoppingCart.splice(product_index, 1);
      setShoppingCart([...shoppingCart])
    }
    //We set the handler to recharge the data
    setEventHandlerShoppingCart(1);
  };

  //Function to incremet the product amount on the shopping cart 
  const incrementProductAmount = (product_index) => {
    //We search the product on the list 
    let product = shoppingCart[product_index];
    //We affect the product data 
    product.amount = product.amount + 1;
    product.cantidadItem = product.amount
    //We set the handler to recharge the data
    setEventHandlerShoppingCart(1);

  };


  //Function to clear the shopping cart
  const clearShoppingCart = () => {
    //We set the shopping cart to empty
    setShoppingCart([]);
    //We close the modal
    setShowModalShopingCart(false)
    //We set the handler to recharge the data
    setEventHandlerShoppingCart(1);
  };


  //Hook to calculate the totals of the invoice when a product is changed on the shopping cart
  useEffect(() => {
    calculateInvoiceTotals(shoppingCart);
  }, [shoppingCart]);

  //Hook to calculate the totals of the invoice if some product is deleted or incremented on the shopping cart 
  useEffect(() => {
    calculateInvoiceTotals(shoppingCart);
  }, [eventHandler]);

  //Hook to recharge the component
  useEffect(() => {
    calculateInvoiceTotals(shoppingCart);
    setEventHandlerShoppingCart(0);
  }, [eventHandlerShoppingCart]);

  return (
    <>

      <Modal
        show={showModalShopingCart}
        fullscreen
        onHide={() => setShowModalShopingCart(false)}
      >
        {/* <Modal.Header className='m-0 p-0'>
                    <button className='btn btn-primary btn-menu-vending  btn-right-border'>
                        <i className="uil uil-arrow-left icon-button"></i>
                        <br />
                 
                    </button>
                    <div className='bd-highlight  w-100'>
                        <h2 className='title-modal-stock text-center'>
                            <i className="uil uil-web-section mx-2"></i>
                            Área de Abastecimiento
                        </h2>
                    </div>
                </Modal.Header> */}
        <Modal.Body className='p-0 m-0 d-flex justify-content-center'>
          <div className='vending '>
            <div className="flex-grow-1 flex-shrink-0  ">
              <section className='d-flex align-items-center flex-column bd-highlight vh-100'>
                <div className='bd-highlight w-100 border-bottom m-0 p-0 bg-white'>
                  <div className='d-flex justify-content-between align-content-center align-items-center'>
                    <button className='btn btn-primary btn-menu-vending  btn-right-border border-0' onClick={() => { setShowModalShopingCart(false) }}>
                      <i className="uil uil-arrow-left icon-button"></i>
                    </button>
                    <h2 className='title-modal-cart text-center'>
                      <i className="uil uil-shopping-cart icon-button"></i>
                      Carrito
                    </h2>
                    <button className="btn btn-primary btn-menu-vending  btn-left-border border-0">
                      <span className="icon-button fw-bold px-3">{shoppingCart.length}</span>
                    </button>
                  </div>
                </div>
                <div className='mb-auto bd-highlight h-100 w-100 shopping-wrapper bg-light'>

                  {!shoppingCart.length
                    ? <div className='empty-cart'><Empty title="Vacío" msg="No se ha añadido ningún producto al carrito" img='' /> </div>
                    : shoppingCart.map((product, index) => (

                      <div className="d-flex align-items-center shadow-sm rounded m-1 bg-white mt-4 mx-3">

                        <div className="flex-shrink-0">
                          <img className='img-product-cart border rounded' src={product.img} />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <div className="d-flex bd-highlight d-flex align-items-center">
                            <div className="bd-highlight title-product-shop-cart text-truncate name-product-cart mt-1" >{product.name} </div>
                            <div className=" bd-highlight title-product-shop-cart mt-1">
                              <div className='d-flex justify-content-around'>
                                <button className='btn round-button-count ' onClick={e => decrementProductAmount(index)}>-</button>
                                <p className='mx-4'>{product.amount}</p>
                                <button className='btn  round-button-count ' onClick={e => incrementProductAmount(index)}>+</button>
                              </div>
                            </div>
                            <div className=" bd-highlight title-product-price">
                              $ { product.price.toFixed(2)
                              }</div>

                            <div className='bd-highlight  mx-2'>
                              <button className='btn btn-outline-danger border-0' title='Borrar Producto'>
                                <i className="uil uil-trash-alt icon-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                </div>
                <div className='bd-highlight w-100 mx-4'>
                  <div className="d-flex justify-content-center">
                    <button className='btn btn-primary btn-vending-product btn-vending btn-menu-vending w-100 mx-4' onClick={() => { setShowModalShopingCart(false) }}>
                      <i className="uil uil-plus"></i>
                      &nbsp;&nbsp;Agregar más productos
                    </button>
                  </div>
                  <div className='d-flex flex-column flex-sm-row mb-5 mx-4'>
                    <div className='flex-grow-1 flex-shrink-0 pb-2 bg-light rounded  p-3 shadow-sm'>
                      <div className='totals-labels d-flex justify-content-between mt-1'>
                        <p > Descuento:</p>
                        <p className='label-price'>$ {invoiceTotalDiscount.toFixed(2)}  </p>
                      </div>
                      <div className='totals-labels d-flex justify-content-between mt-1'>
                        <p > Subtotal:</p>
                        <p className='label-price'>$ {invoiceSubtotal.toFixed(2)}  </p>
                      </div>

                      {taxesArrayAll.map((tax) => (
                        <>
                          {tax.total.toFixed(2) > 0 ?
                            <div className='totals-labels d-flex justify-content-between mt-1'>
                              <p>  Impuesto {tax.tax}:</p>
                              <p className=' label-price '>$ {tax.total.toFixed(2)} </p>
                            </div> : <></>}
                        </>
                      ))}
                      <div className='totals-labels d-flex justify-content-between mt-1'>
                        <p> Total:</p>
                        <p className='label-price text-success ' >$ {invoiceTotal.toFixed(2)}  </p>
                      </div>
                    </div>
                    <div className='flex-grow-1 flex-shrink-0 ps-5 mt-4'>
                      <button className='btn btn-danger btn-vending-product btn-menu-vending w-100 bg-danger' onClick={clearShoppingCart} data-bs-dismiss="modal">
                        Cancelar
                      </button>
                      <br />
                      <button className='btn btn-success btn-vending-product btn-menu-vending mt-4   w-100  bg-success' data-bs-dismiss="modal" onClick={() => (setShowModalPayment(true))}>
                        Realizar Pago
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <VendingMetPayments showModalPayment={showModalPayment} setShowModalPayment={setShowModalPayment} shoppingCart={shoppingCart} invoiceSubtotal={invoiceSubtotal} invoiceTotal={invoiceTotal} invoiceTotalItbms={invoiceTotalItbms} invoiceTotalDiscount={invoiceTotalDiscount} dataClient={dataClient} setDataClient={setDataClient} setShoppingCart={setShoppingCart} setShowModalShopingCart={setShowModalShopingCart} setEventHandlerShoppingCart={setEventHandlerShoppingCart} setEventHandlerMenu={setEventHandlerMenu} setStatusScreen={setStatusScreen} statusComunicationVending={statusComunicationVending} calculateInvoiceTotals={calculateInvoiceTotals} groups={groups} taxesArrayAll={taxesArrayAll} orderNumber={orderNumber} setOrderNumber={setOrderNumber} payments={payments} setPayments={setPayments} oledScreenPayments={oledScreenPayments} setAtivityTimer={setAtivityTimer}></VendingMetPayments>

    </>
  )
}

export default VendingModalCart