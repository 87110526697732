import axios from 'axios';


const server = axios.create({
    baseURL: "",
    headers: { 'Content-Type': 'application/json' }
})

export class VendingSnakkyFetchAPI {
    static async keypad(data) {

        const data_json = JSON.stringify(data); 
        let response = await axios.post("http://localhost:9090/serialFlush", data_json);
        return response;
    }


    static async getTemp(data) {

        const data_json = JSON.stringify(data); 
        let response = await axios.post("http://localhost:9090/askTemp", data_json);
        return response;
    }


    static async getDrop(data) {

        const data_json = JSON.stringify(data); 
        let response = await axios.post("http://localhost:9090/askDropSensor", data_json);
        return response;
    }

    static async clearData(data) {
        const data_json = JSON.stringify(data); 
        let response = await axios.post("http://localhost:9090/clearDropSensor", data_json);
        return response;
    }
}