import React, { useState, useEffect, useRef } from 'react'

import { useWindowSize } from '../../hooks/useWindowSize';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { GroupsFechtAPI } from '../../api/Groups'
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import img_1 from '../../assets/img/vending/products/Coke.png'
import { Container, Row, Col } from 'react-bootstrap';
import { GiDivingDagger } from 'react-icons/gi';
import { FaInfoCircle } from 'react-icons/fa';
import { PublicityFechtAPI } from '../../api/Publicity';

import VendingModalCategories from './VendingModalCategories';
import VendingModalCart from './VendingModalCart';
import VendingModalProduct from './VendingModalProduct';
import VendingStock from './VendingStock';
import VendingIdel from './VendingIdel';
import { BusinessFetchAPI } from '../../api/Business';
import { MachinesFetchAPI } from '../../api/Machines';
import { StatusMenu } from '../../api/StatusMenu';
import AlertSmall from '../../components/Alerts/AlertSmall';
import VendingModalOperator from './VendingModalOperador';
import { VendingFetchAPI } from '../../api/LocalRequest/Vending';
import VendingModalSupport from './VendingModalSupport';
import TimerScreen from './TimerScreen';
import { RenderIf } from '../../components/utils/RenderIf';
import stand from '../../assets/img/vending/stand-2.png'
//import 'animate.css';
//Utils
import './vending.css'
import Empty from '../../utils/Empty';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { OrderNumberFetchAPI } from '../../api/Paymentpost/OrderNumber';
import oledComunication from './OledComunication';
import { VendingSnakkyFetchAPI } from '../../api/LocalRequest/VendingSnakky';
import { Refresh } from 'react-flaticons';

function VendingMenu({ setEventHandler, showModalKeyCode, setShowModalKeyCode }) {

  let token = localStorage.getItem("x-access-token");
  let business = JSON.parse(localStorage.getItem('business_vending'));
  let branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'));
  let machine = JSON.parse(localStorage.getItem('machine_vending'));

  const inputRefMenu = useRef(null);
  const [nameBusiness, setNameBusiness] = useState("Ordene aqui")
  const [statusTempTurnNow, setStatusTempTurnNow] = useState(true)
  const [logo, setLogo] = useState("")
  const [groups, setGroups] = useState([]);
  const [ipAndroid, setIpAndroid] = useState("");
  const [selectGroup, setSelectGroup] = useState({ Group: "" })
  const [showModalSupport, setShowModalSupport] = useState(false)
  const [shoppingCart, setShoppingCart] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showModalProduct, setShowModalProduct] = useState(false)
  const [showModalShopingCart, setShowModalShopingCart] = useState(false);
  const [showModalIdel, setShowModalIdel] = useState(false);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [contentSupport, setContentSupport] = useState({ title: "", body: [{ text: "" }, { text: "" }] })
  const [showModalStock, setShowModalStock] = useState(false)
  const [dataClient, setDataClient] = useState({ name: "Cliente", ruc: "0-000-00", dv: "00" });
  const [eventHandlerMenu, setEventHandlerMenu] = useState(0);
  const [eventHandlerTime, setEventHandlerTime] = useState(0);
  const [eventHandlerProduct, setEventHandlerProduct] = useState(0);
  const [temperature, setTemperature] = useState(0);
  const [humidity, setHumidity] = useState(0);
  const [statusComunicationVending, setStatusComunicationVending] = useState(false)
  const [showModalAuthOperator, setShowModalAuthOperator] = useState(false);
  const [statusScreen, setStatusScreen] = useState(false)
  const [time, setTime] = useState("")
  const [activityScreen, setActivityScreen] = useState(0)
  const [cantidadFilas, setCantidadFilas] = useState(0);
  const [count, setCount] = useState(0);
  const [publicity, setPublicity] = useState([]);
  const [video, setVideo] = useState("");
  const [amount, setAmount] = useState(1);
  const [showModaloutService, setShowModaloutService] = useState(false)
  const [orderNumber, setOrderNumber] = useState(0);
  const [handlerAutoAdd, setHandlerAutoAdd] = useState(false)
  const [payments, setPayments] = useState([]);
  const [activityTimer, setAtivityTimer] = useState(3);
  const [priority, setPriority] = useState(0);
  const [videoNow, setVideoNow] = useState('')
  let intervalTemp = 0;
  let activity = 0;
  let screenPause = 0;


  //=====================================FUNCTIONS INPUT MENU POSITIONS==========================================================
  const handlerChangeInputMenu = (txt) => {
    
    oledComunication(0, '2,0')
    /*setTimeout(() => {
      oledComunication(0, '2,1,4,40,20,' + txt)
    }, 50);
*/
    if (txt === '00') {
      localStorage.setItem("GroupsByMachine", '');

      setTimeout(() => {
        oledComunication(0, '2,0')
      }, 100);

      setTimeout(() => {
        oledComunication(0, '2,1,1,35,30,Preparando')
      }, 150);

      setTimeout(() => {
        oledComunication(0, '2,1,1,40,40,maquina...')
      }, 200);


      setTimeout(() => {
        window.location.reload(true);
      }, 250);

    } else if (txt.length < 3) {
      setTimeout(() => { if (txt.length == 2) { selectedProduct(txt) } }, 400)

    } else {
      clearInputMenu(txt[2])
      setTimeout(() => { if (txt.length == 2) { selectedProduct(txt) } }, 400)

    }


    function selectedProduct(txt) {
      oledComunication(0, '2,0')
      let newList = groups.filter(({ group_id, amount }) => group_id != null && amount > 0)
      console.log(newList);
      let selectPosition = newList.find(({ positionX, positionY }) => positionX === parseInt(txt[0]) && positionY === parseInt(txt[1]))

      if (selectPosition != undefined) {
        clearStatussentorandlimitDigit(1, "2,1,4,40,20,")
        console.log("Encontre algo");
        console.log(selectPosition);
        getGroupById(selectPosition.group_id, selectPosition.positionX, selectPosition.positionY, selectPosition.amount, selectPosition.position_id, 0, selectPosition.detail_group)
        setHandlerAutoAdd(true)

        //show in oled screen
        setTimeout(() => {
          oledComunication(0, '2,1,1,0,10,' + txt + ":" + selectPosition.name)
        }, 50);
        setTimeout(() => {
          oledComunication(0, '2,1,3,20,30,$' + parseFloat(selectPosition.price).toFixed(2))
        }, 100);

        setTimeout(() => {
          oledScreenPayments()
        }, 1000);
      } else {
        clearStatussentorandlimitDigit(2, "2,1,4,40,20,")
        oledComunication(0, '2,1,1,25,20,Este producto')
        setTimeout(() => {
          oledComunication(0, '2,1,1,35,30,no existe.')
          setTimeout(() => {
            //RESET OLEDSCREEN
            initOledScreen();
          }, 1000);
        }, 50);
      }

      clearInputMenu()
    }
  }

  const clearInputMenu = (txt) => {
    if (txt == undefined) { inputRefMenu.current.value = "" } else { inputRefMenu.current.value = txt }
    inputRefMenu.current.focus()
    console.log('Limpiar');
  }

  const handlerActionLayoutMenu = () => {
    if (machine.machine_subtype_code == "VCG") {

    } else {
      inputRefMenu.current.focus()
    }
  }
  //===============================================================================================


  // get status change of the info to  menu
  const getStatusContentMenu = async () => {
    let dataGroupsLocal = localStorage.getItem("GroupsByMachine")
    if (dataGroupsLocal == undefined || dataGroupsLocal == null || dataGroupsLocal == "" || dataGroupsLocal == []) {
      getGroupsByMachine();
      getCategories();
    } else {
      dataGroupsLocal = JSON.parse(localStorage.getItem("GroupsByMachine"))
      try {
        const response = await StatusMenu.getContentMenu(token)
        console.log(response.data.data.content_status);

        if (response.data.data.content_status == false) {
          setGroups(dataGroupsLocal)
        } else {
          getGroupsByMachine();
          getCategories();
        }

      } catch (err) {
        console.log(err);
      }
    }
  }


  //Function get the groups by machine
  const getGroupsByMachine = async () => {
    try {
      const response = await GroupsFechtAPI.getGroupsByMachine(machine.id, token);
      //console.log(response);
      const response1 = await GroupsFechtAPI.getGroupsByBranchoffice(branchoffice.id, token)

      let groups = response.data.groups


      const groupIndex = response1.data.data.reduce((acc, element) => {
        if (Array.isArray(acc[element.group_id]) && acc[element.group_id].length > 0) {
          acc[element.group_id].push(element);
        } else {
          acc[element.group_id] = [element]
        }
        return acc
      }, {})

      console.log(groupIndex);

      //We add to the groups the info detail 
      groups = groups.map(element => {
        return {
          ...element,
          detail_group: groupIndex[element.group_id] === undefined ? [] : groupIndex[element.group_id]
        }
      });

      console.log(groups);


      console.log(response1);
      console.log(response);
      localStorage.setItem("GroupsByMachine", JSON.stringify(groups))
      setGroups(groups)
      console.log(response.data.groups);
    } catch (err) {
      setGroups([])
      console.log(err);
    }
  }


  //Function get the groups by machine
  const getGroupsByMachineByCategory = async (category_id) => {
    //console.log(category_id);
    try {
      const response = await GroupsFechtAPI.getGroupByCategoryAndPosition(machine.id, category_id, token);
      console.log(response);
      setGroups(response.data.groups)

    } catch (err) {
      setGroups([])

      console.log(err);
    }
  }

  //Function get group by id
  const getGroupById = async (group_id, X, Y, stock, position_id, index, detail_group) => {
    setAtivityTimer(7)
    setStatusTempTurnNow(false)
    console.log("index del producto: " + index);
    setShowModalProduct(true);

    if (detail_group != undefined) {
      let group = ''

      if (Array.isArray(detail_group)) {
        group = detail_group[0]
      } else {
        group = detail_group
      }
      group.preparation = group.group_preparation
      //set price with dicount
      let discount_item = 0;
      group.unit_discount = discount_item;
      group.product_discount = discount_item;
      group.descuento = discount_item;
      group.discount = discount_item;

      setSelectGroup({ ...group, X: X, Y: Y, stock: stock, position_id: position_id, index: index })
    } else {
      LoadingAlert()
      try {
        const response = await GroupsFechtAPI.getGroupById(group_id, token)
        let group_item = response.data.data

        //set price with dicount
        let discount_item = 0;

        group_item.unit_discount = discount_item;
        group_item.product_discount = discount_item;
        group_item.descuento = discount_item;
        group_item.discount = discount_item;
        //--------------------------------------------------
        setSelectGroup({ ...group_item, X: X, Y: Y, stock: stock, position_id: position_id, index: index })
        AlertSmall("Consulta exitosa")

        groups.forEach((group) => {
          if (group.group_id == group_id) {
            group.detail_group = { ...group_item, X: X, Y: Y, stock: stock, position_id: position_id, index: index }
          }
        });


        setGroups(groups)
        localStorage.setItem("GroupsByMachine", JSON.stringify(groups))
      } catch (err) {
        console.log(err);
      }
    }
  }



  //get temperature of the vending machine
  const getTemp = async () => {
    if (machine.machine_subtype_code === "VCG") {
      let data = { funcion: "GETTEMP", datos: [{ X: "0", Y: "0" }] }
      try {
        const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
        const response = await VendingFetchAPI.operation(response_machine.data.data.android_ip, data);
        let data_Response = JSON.parse(response.data.data.RespuestaGetCold)
        //console.log(response);
        setTemperature(data_Response[0])
        setHumidity(data_Response[2])
        setStatusComunicationVending(true)

      } catch (err) {
        setStatusComunicationVending(false);
        console.log(err);
      }
    } else {
      //setStatusComunicationVending(true)
      if (statusTempTurnNow == false) {

        console.log(statusTempTurnNow)
        try {
          let data = [{ "comandaVendType": 0, "vendingCode": "4,1" }]
          const response = await VendingSnakkyFetchAPI.keypad(data)
          console.log(response)

          setStatusComunicationVending(true)
        } catch (err) {
          setStatusComunicationVending(false)
        }

        setTimeout(async () => {
          console.log(statusTempTurnNow)
          try {
            let data = [{ "comandaVendType": 0, "vendingCode": "4,1" }]
            const response = await VendingSnakkyFetchAPI.getTemp(data)
            console.log(response)

            let dataInformation = response.data.Informacion.split(',')

            setTemperature(dataInformation[1])
            setHumidity(dataInformation[0])
            console.log(response.data.Informacion)

            setStatusComunicationVending(true)
          } catch (err) {
            setStatusComunicationVending(false)
          }
        }, 500);


      }
    }

  }

  const getBusinessConfig = async () => {
    try {
      const response = await BusinessFetchAPI.getBusinessByidConfig(business.id, token);
      console.log(response.data.data.logo);
      setLogo(response.data.data.logo)


      if (machine.machine_subtype_code == "VGG") {
        localStorage.setItem('positions', JSON.stringify([12, 12, 12, 12, 12, 12, 12, 12]))
      }


    } catch (err) {
      console.log(err);
    }
  }

  //Function get categories
  const getCategories = async () => {
    try {
      //const response = await CategriesPosFetchAPI.getCategoriesByBranchOffices(branchoffice.id, 1, token);
      const response = await MachinesFetchAPI.getMachineCategories(machine.id, token);
      localStorage.setItem("Categories", JSON.stringify(response.data.categories))
      console.log(response.data.categories);
      setCategories(response.data.categories)
      //setCategories(response.data.data)

    } catch (err) {
      setCategories([])
      console.log(err);
    }
  }

  // add product to shopingcart
  const addProduct = () => {


    getOrderNumber()
    getTemp();

    if (statusComunicationVending == false) {
      setShowModalProduct(false)
      setShowModaloutService(true)
      setAtivityTimer(0)
    } else {
      setShowModalProduct(false)
      setShowModalShopingCart(false);
      setShowModalPayment(true)
      setStatusScreen(true);
      //We put the amount in the product
      selectGroup.amount = amount;
      selectGroup.cantidadItem = amount;
      selectGroup.delivery_type = "para llevar";
      selectGroup.discount = 0;
      //We set the product in the shopping cart
      let stateaddProduct = true;
      //We check if the product is in the shopping cart
      shoppingCart.forEach(group => {
        if (group.group_id == selectGroup.group_id) {
          group.amount = (group.amount + amount);
          group.cantidadItem = (group.cantidadItem + amount);
          stateaddProduct = false;
        }
      });

      //condition to add product or increment
      if (stateaddProduct == true) {
        setShoppingCart([...shoppingCart, selectGroup]);
      } else {
        setShoppingCart(shoppingCart);
      }

      //initialize state
      setAmount(1);
      //Event handler
      setEventHandler(1);
    }

  }

  // get order number in process
  const getOrderNumber = async () => {
    let branchoffice_vending = JSON.parse(localStorage.getItem("branchoffice_vending"));
    let token = localStorage.getItem('x-access-token');
    try {
      const response = await OrderNumberFetchAPI.getOrderNumber(branchoffice_vending.id, token)
      //console.log(response);
      setOrderNumber((parseInt(response.data.last_transaction_code) + 1));
    } catch (err) {
      console.log(err);
    }
  }





  //View list categories
  const viewCategories = () => {
    const listCategories = categories.map((categorie) => (
      <>

      </>
    ));
    return listCategories
  }

  //View list all groups (Products)
  const viewGroups = () => {
    const listGroups = groups.map((product) => (
      <></>
    ));
    return listGroups
  }

  //View group (product) select
  const viewGroup = () => {
    return (<>
    </>)
  }

  //get time update
  let intervalTime = 0
  const getTime = () => {
    if (publicity.length > 0) {
      if (activity > 3) {
        if (activity == 4) {
          
          //----------------------------
          setShowModalPayment(false);
          setShowModalProduct(false);
          setShowModalAuthOperator(false);
        }
      }
    }
    console.log(activity)

    //calculate time 
    let date = new Date();
    date = date.toString().slice(15, 21)
    let datasegmentTime = date.split(":")

    let hour = parseInt(datasegmentTime[0])
    let statusTime = ""
    if (hour > 12) {
      hour = (hour - 12)
      statusTime = "PM"
    } else {
      statusTime = "AM"
    }

    let time = hour + ":" + datasegmentTime[1] + " " + statusTime
    setTime(time);

    //setEventHandlerTime(1)
  }

  //================================OLED=======================================================
  const initOledScreen = () => {

    oledComunication(0, '5,0,15,0') //color led exterior vending
    oledComunication(0, '2,0')
    setTimeout(() => {
      oledComunication(0, '2,1,1,25,20,Seleccione un')
    }, 50);

    setTimeout(() => {
      oledComunication(0, '2,1,1,35,30,producto.')
    }, 100);

    setTimeout(() => {
      getTemp()
    }, 2000);
  }

  const oledScreenPayments = () => {

    oledComunication(0, '5,0,9,0') //color led exterior vending
    oledComunication(0, '2,0')
    setTimeout(() => {
      oledComunication(0, '2,1,1,20,0,Como desea pagar ')
    }, 50);




    let array1 = []
    let array2 = []

    payments.forEach((payment, index) => {
      if ((index + 1) <= 4) {
        array1.push(payment)
      } else {
        array2.push(payment)
      }
    })

    array1.forEach((payment, index) => {

      let name = ''
      if (payment.payment == 'MasterCard') {
        name = 'MCard'
      } else {
        name = payment.payment
      }
      setTimeout(() => {
        oledComunication(0, '2,1,1,0,' + ((index + 1) * 10) + ',' + (index + 1) + '.' + name + ' ')
      }, (100 + ((index + 2) * 10)));
    })

    array2.forEach((payment, index) => {

      let name = ''
      if (payment.payment == 'MasterCard') {
        name = 'MCard'
      } else {
        name = payment.payment
      }
      //setTimeout(() => {
      oledComunication(0, '2,1,1,48,' + ((index + 1) * 10) + ',' + (index + 5) + '.' + name)
      //}, (100 + ((index + 2) * 10)));
    })

    setTimeout(() => {
      oledComunication(0, '2,1,0.5,30,55,0.CANCELAR')
    }, 500);
  }

  const clearStatussentorandlimitDigit = async (data, settingtxt) => {
    console.log("entre aqui flekflwenfoernf");
    try {

      await VendingSnakkyFetchAPI.clearData({ "limit": data, "setting": settingtxt })

    } catch (err) {
      console.log(err);
    }
  }

  const refresh = () => {
    window.location.reload(true);
  }

  //===========================================================================================

  //Hook to load the initial info
  useEffect(() => {
    (async () => {
      getPublicity();
      getBusinessConfig();
      getTime();
      setStatusTempTurnNow(true)
      setNameBusiness(business.name)

      inputRefMenu.current.focus()
      //getGroupsByMachine();
      //getCategories();
      getStatusContentMenu();
      //getTemp();
      if (machine.machine_subtype_code == "VGG") {
        clearStatussentorandlimitDigit(2, "2,1,4,40,20,")
        initOledScreen()
      } else {
        getTemp()
      }
    })()

    setEventHandlerMenu(0)
  }, [eventHandlerMenu]);



  //timer activity and time
  useEffect(() => {


    const interval = setInterval(() => {
      activity = (activity + 1)
      getTime();
      getTemp();
      //console.log("Secuencia " + activity);
    }, 60000); // Ejecutar cada 1000 ms (1 segundo)
    return () => {
      clearInterval(interval); // Limpiar el intervalo al desmontar el componente
    };
  }, [statusTempTurnNow]);


  //click  in the screen
  useEffect(() => {
    const handleClick = (event) => {
      if (activity > 1000) {
      } else {
        activity = 0;
      }
    };

    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []); // Se ejecuta una vez al montar el componente

  //add item auto
  useEffect(() => {
    if (handlerAutoAdd == true) {
      addProduct()
    }
  }, [handlerAutoAdd]);

  useEffect(() => {
    // Establecer un intervalo
    const intervalId = setInterval(() => {
      // Validar el valor actualizado del contador con una instrucción if

      console.log("ahora estoy en "
        + activityTimer + " y la prioridad es " + priority
      );
      if (priority == 0) {
        switch (activityTimer) {
          case 0:
            refresh()
            break;
          case 1:
            // activity 1 determines that there is navigation activity
            setAtivityTimer(0);
            break;
          case 2:
            break;
          case 3:
            setShowModalIdel(true)
            break;
          case 5:
            setAtivityTimer(6);
            break;
          case 6:
            setAtivityTimer(7);
            break;
          case 7:
            setAtivityTimer(1);
            break;
        }

      } else {
        //priority event
        //console.log(" hay prioridad");
        if (activityTimer == 3) {//actividad 3 determina que a finalizado la transaccion
          setAtivityTimer(4);
        } else if (activityTimer == 4) {//activity 4 redirects to the first step and restarts the transaction
          setAtivityTimer(0);

        }
      }
    }, 30000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [activityTimer, priority]);


  //Filas 
  const ProductRow = ({ products, key, index }) => {

    return (<>
      <div className=' boxs-stands position-relative pb-lg-1 pb-1'>
        <div className='stand-absolute d-flex justify-content-lg-around justify-content-between no-scroll mt-lg-4 mt-2'>
          {products.map((product) => (
            <>
              {product.amount > 0 ?
                <div className='border-cards-items rounded-3 position-relative p-0  mx-1 border-vending-secondary-2 '>
                  <div className="my-0 " key={product.group_id} onClick={() => (getGroupById(product.group_id, product.positionX, product.positionY, product.amount, product.position_id, index, product.detail_group))}>
                    <div className="d-flex justify-content-center p-1" >
                      <img src={product.img} className="vending-img-product rounded-3"
                        alt="Product"
                        loading="lazy"
                        type='image/gif'
                      />
                    </div>
                    <div className="mt-0 text-center">
                      <h5 className="title-product-menu text-dark">{product.name}</h5>
                      <h6 className="title-price-menu text-center px-2">$ {product.price.toFixed(2)} </h6>
                    </div>
                  </div>
                  <div className='text-position-item position-absolute start-0 top-0 text-bg-primary px-2 py-1 ms-1 mt-1'>
                    <span className='fw-bold'>
                      {product.positionX}-{product.positionY}
                    </span>
                  </div>
                </div>
                : <></>
              }
            </>
          ))}
        </div>
        <div className='position-absolute stan'>
          <img src={stand} className='vendindg-bg-stand' alt="Stand" />
        </div>

      </div>

    </>
    );
  };

  const handlerSuppor = async () => {
    setAtivityTimer(7)
    try {
      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
      console.log(response_machine);
      setContentSupport({ title: "Solicitud de ayuda", body: [{ "text": "Maquina vending: " + response_machine.data.data.machine_name + "solicitan ayuda." }, { text: "1" }] })
      setShowModalSupport(true)
    } catch (err) {
      console.log(err);
    }

  }

  function ordenarAsc(p_array_json, p_key) {
    let newArray = p_array_json.sort(function (a, b) {
      return a[p_key] > b[p_key];
    });


    return (newArray.reverse())
  }


  const RowsMenu = () => {
    const [products, setProducts] = useState([]);

    const { width } = useWindowSize();
    const rows = [];
    const rowSize = width <= 767 ? 2 : 3;

    let positions = JSON.parse(localStorage.getItem('positions'))
    let array = []

    if (positions != undefined) {
      if (positions.length > 0) {
        array = groups.filter(producto => producto.name !== null && producto.amount > 0 && producto.positionX < (positions.length + 1));
      } else {
        //ErrorAlert('', 'No hay posiciones', 'error')
        console.log("No hay posiciones");
      }
    }
    else {
      //ErrorAlert('', 'No hay parametro de posiciones', 'error')
      console.log("No hay parametro de posiciones");
    }

    const productNoNulls = [];

    array.forEach(p => {
      if (productNoNulls.findIndex(item => item.group_id === p.group_id) === -1) {
        // No existe; al detectar que no existe el mismo nombre, "la copiamos"

        productNoNulls.push(p);
      }
    });

    ordenarAsc(productNoNulls, 'positionX');
    console.log(productNoNulls);

    for (let i = 0; i < productNoNulls.length; i += rowSize) {
      const productsInRow = productNoNulls.slice(i, i + rowSize);
      rows.push(<ProductRow products={productsInRow} key={i} index={i} />);
    }
    return <div>
      {rows}
    </div>;
  };

  const getPublicity = async () => {

    try {
      const response = await PublicityFechtAPI.getPublicity(machine.id, token);

      setPublicity(response.data.publicity)
      setVideo(response.data.publicity)
      setVideoNow(response.data.publicity[0].video_url)
      activity = 0
      //console.log(response.data.publicity)

    } catch (err) {
      setPublicity([])
      activity = 1000
      console.log(err)
    }
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleContextMenu = (e) => {
    e.preventDefault(); // Evita que aparezca el menú contextual
  };

  const handleMouseDown = (e) => {
    if (e.button === 2) {
      e.preventDefault(); // Evita que el clic derecho dispare el menú contextual
    }
  };


  return (
    < >
      <main className='vending-container-main d-flex justify-content-center ' onContextMenu={(e) => handleContextMenu(e)} onMouseDown={(e) => handleMouseDown(e)} onClick={() => (handlerActionLayoutMenu(), inputRefMenu.current.focus())}>
        <section className='vending-flex-container border'>
          <article>
            <header className='border-vending-secondary mt-1 mx-2 pe-1 pb-1'>
              <div className="d-flex justify-content-between align-items-center m-1 ">
                <button className=' btn-menu-vending  '
                  data-bs-toggle="modal" data-bs-target="#CategoriesModal">
                  <i className="uil uil-search-alt icon-button"></i>
                </button>
                <VendingModalOperator logo={logo} showModalAuthOperator={showModalAuthOperator} setShowModalAuthOperator={setShowModalAuthOperator} humidity={humidity} temperature={temperature} setShowModalStock={setShowModalStock} showModalStock={showModalStock} setEventHandlerMenu={setEventHandlerMenu} showModalKeyCode={showModalKeyCode} setShowModalKeyCode={setShowModalKeyCode} setStatusScreen={setStatusScreen} nameBusiness={nameBusiness} setAtivityTimer={setAtivityTimer}></VendingModalOperator>
                <button className=' btn-menu-vending  '
                  onClick={async () => { handlerSuppor() }}
                >
                  <span className='position-relative '>
                    <i className="uil uil-info-circle icon-button "></i>
                  </span>
                </button>
              </div>
              <div className=' d-flex justify-content-between align-items-center'>

                <div className='menu-vending-title '  >
                  {/* <button className='btn btn-primary display-2' onClick={() => (setShowModalStock(true))}> stock</button> */}
                </div>

              </div>
            </header></article>
          <article className='vending-content-items '>
            <div className='' onContextMenu={(e) => handleContextMenu(e)} onMouseDown={(e) => handleMouseDown(e)}>
              <section className='menu '>
                <div className="vending-menu-products no-scroll  mb-1 mx-auto position-relative ">
                  {RowsMenu()}
                </div>
              </section>
            </div>
          </article>

          {/* 3 */}

          <article className=' border-vending-secondary mx-2 mb-2'>
            <div className='bottom-vending-parameters d-flex justify-content-around align-items-center'>
              <div className='menu-vending-temp text-center d-flex align-content-center align-items-center text-muted px-2 mx-1' >
                <i className="uil uil-temperature icon-button-params"></i>
                <h3 className='mt-2' >
                  {temperature}° C
                </h3>
              </div>
              <div className='menu-vending-temp text-center d-flex align-content-center align-items-center text-muted mx-1 '>

                <i className="uil uil-thermometer icon-button-params"></i>
                <h3 className='mt-2' >
                  {humidity}%
                </h3>

              </div>
              <div className='menu-vending-time text-center d-flex align-content-center align-items-center text-muted mx-1 '>
                <i className="uil uil-clock icon-button-params"></i>
                <h3 className='mt-2 '>{time}</h3>
              </div>
            </div> </article>
        </section>
      </main>

      {/** App modals */}
      <VendingModalCategories categories={categories} setGroups={setGroups} getGroupsByMachineByCategory={getGroupsByMachineByCategory} getGroupsByMachine={getGroupsByMachine}></VendingModalCategories>
      {/*<VendingModalCart setShoppingCart={setShoppingCart} shoppingCart={shoppingCart} showModalShopingCart={showModalShopingCart} setShowModalShopingCart={setShowModalShopingCart} eventHandler={eventHandlerProduct} dataClient={dataClient} setDataClient={setDataClient} showModalPayment={showModalPayment} setShowModalPayment={setShowModalPayment} setEventHandlerMenu={setEventHandlerMenu}></VendingModalCart>*/}

      <VendingModalProduct selectGroup={selectGroup} setShoppingCart={setShoppingCart} shoppingCart={shoppingCart}
        showModalProduct={showModalProduct} setShowModalProduct={setShowModalProduct} setShowModalShopingCart={setShowModalShopingCart}
        setEventHandlerMenu={setEventHandlerMenu} showModalShopingCart={showModalShopingCart} dataClient={dataClient} setDataClient={setDataClient}
        groups={groups} setSelectGroup={setSelectGroup} getGroupById={getGroupById} showModalPayment={showModalPayment} setShowModalPayment={setShowModalPayment}
        setStatusScreen={setStatusScreen} statusScreen={statusScreen} getTemp={getTemp} statusComunicationVending={statusComunicationVending} amount={amount}
        setAmount={setAmount} showModaloutService={showModaloutService} setShowModaloutService={setShowModaloutService} orderNumber={orderNumber}
        setOrderNumber={setOrderNumber} addProduct={addProduct} getOrderNumber={getOrderNumber} payments={payments} setPayments={setPayments}
        oledScreenPayments={oledScreenPayments}
        setStatusTempTurnNow={setStatusTempTurnNow} setAtivityTimer={setAtivityTimer}>


      </VendingModalProduct>
      <VendingStock humidity={humidity} temperature={temperature} setShowModalStock={setShowModalStock} showModalStock={showModalStock} setEventHandlerMenu={setEventHandlerMenu} eventHandlerMenu={eventHandlerMenu} setStatusScreen={setStatusScreen} setAtivityTimer={setAtivityTimer}></VendingStock>
      <VendingModalSupport showModalSupport={showModalSupport} setShowModalSupport={setShowModalSupport} contentSupport={contentSupport} setEventHandlerMenu={setEventHandlerMenu}  setAtivityTimer={setAtivityTimer}></VendingModalSupport>


      {/* Modal Info */}

      <Modal show={show} onHide={handleClose}
        size="lg"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="modal-body p-5">
            <div className="pb-4 border-bottom-0">
              <h2 className="fw-bold mb-0 title-modal-categories  pt-2">
                Necesitas Ayuda
              </h2>
              {/* <button type="button" className="btn-close close-x text-muted border rounded-pill" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <ul className="d-grid gap-4 my-5 list-unstyled">
              <li className="d-flex gap-4">

                <div>
                  <h5 className="mb-0">Grid view</h5>
                  Not into lists? Try the new grid view.
                </div>
              </li>
              <li className="d-flex gap-4">
                <div>
                  <h5 className="mb-0">Bookmarks</h5>
                  Save items you love for easy access later.
                </div>
              </li>
              <li className="d-flex gap-4">
                <div>
                  <h5 className="mb-0">Video embeds</h5>
                  Share videos wherever you go.
                </div>
              </li>
            </ul>
            <div className='d-flex justify-content-center'>
              <button type="button"
                className="btn btn-lg btn-primary btn-vending-product 
              
             btn-menu-vending"
                onClick={handleClose}>¡Muchas Gracias!</button>
            </div>
          </div>


        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

      <VendingIdel showModalIdel={showModalIdel} setShowModalIdel={setShowModalIdel} publicity={publicity} video={video} inputRefMenu={inputRefMenu} videoNow={videoNow} setVideoNow={setVideoNow} ></VendingIdel>
      <TimerScreen></TimerScreen>


      {/*Input navegation */}

      <div className='position-absolute '>
        <input id="inputlistenerMenu" ref={inputRefMenu} type='text' inputMode="none" onChange={(e) => handlerChangeInputMenu(e.target.value)} className='vending-input-qr' autoFocus></input>
      </div>

    </>
  )
}

export default VendingMenu