
import { VendingSnakkyFetchAPI } from "../../api/LocalRequest/VendingSnakky";

async function oledComunication(operator, command, jsonAlter, ) {
    
    let data = {}
    const setDataOled = async () => {
        let json= [{"comandaVendType":operator, "vendingCode": command }]
        console.log(json);

        //img Json oled
        if(operator==2 || operator==3 ){
            json= jsonAlter
        }
        try {
            const response = await VendingSnakkyFetchAPI.keypad(json);
            data = response
        } catch (err) {
            console.log(err);
            data = err
        }
    }

    await setDataOled();
    return data
}

export default oledComunication