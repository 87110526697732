import React, { useState, useEffect, useRef } from 'react'
//Component
import { RenderIf } from '../../components/utils/RenderIf';
import { Button, Modal } from 'react-bootstrap'
import QRCode from "react-qr-code";
// import {QRCodeSVG} from 'qrcode.react';
import { useWindowSize } from '../../hooks/useWindowSize';

//pictures

import checkimg from './img/check.gif';
import YappyStep from './img/YappyStep.png';
import logoYappy from './img/yappy.png';
import cancel from './img/error.gif';
import loading from './img/loading.gif';
import oledComunication from './OledComunication';

function ModalYappyQr({ background, paymentInfo, modalYappyQr, setModalYappyQr, dataYappy, setDataHash, dataHash, hadleCancelYappy, invoiceTotal, orderNumber, inputRefLogYappy, handleChangeYappy, oledScreenPayments }) {

    let branchoffice = { id: "" }
    let dataOrder = { transaction_code: "", total: 0 };
    if (localStorage.getItem("branchoffice") != "" || localStorage.getItem("branchoffice") != undefined) {
        branchoffice = JSON.parse(localStorage.getItem("branchoffice"))
        dataOrder = JSON.parse(localStorage.getItem('Detail_order'))
    }



    //const [routeQr, setRouteQr] = useState("0")

    const updatePage = () => {
        window.location.reload();
    }



    useEffect(() => {

    }, []);

    
    const { width } = useWindowSize();
    const qrSize = width <= 767 ? 140 : 500;

    return (

 
        <Modal
            show={modalYappyQr}
            fullscreen
            onHide={() => setModalYappyQr(false)}>
            <Modal.Body>
                <section className='kiosk-delivery ' onClick={() => inputRefLogYappy.current.focus()} >

                    <div className='d-flex alight-item-center justify-content-center m-1 my-lg-4'>
                        <img src={logoYappy} className='yappy-img'></img>
                    </div>

                    <div className='my-4 py-2 border-vending-primary text-center'>
                        <h2 className=' fw-bold text-msg-footer-2 '>Orden:
                            <span className=' text-msg-footer-2 fw-bold mx-2'>
                                {orderNumber}
                            </span>
                        </h2>

                    </div>
                    <div className='my-4 py-2 border-vending-success text-center'>
                        <h2 className=' fw-bold text-msg-footer-2 '>Total:
                            <span className=' text-msg-footer-2 fw-bold '>
                                ${invoiceTotal}
                            </span>
                        </h2>

                    </div>
                    <div class=" text-dark fw-bold  text-center text-msg-footer-2 ">
                        Escanear qr, para pago

                    </div>
                    <div className=" d-flex align-items-center justify-content-center  border-vending-secondary py-4 position-relative">
                        <QRCode value={dataHash} size={qrSize} />
                    </div>

                    <div className='px-4 pb-1 mt-lg-5 mt-2 text-center'>
                        <img className='mx-auto mb-0 img-step' src={YappyStep}  />
                    </div>
                    <div className='d-flex justify-content-center align-items-center '>
                        <button className="border-vending-danger  p-4 text-msg-footer-2 "
                            onClick={() => ((setDataHash(""), hadleCancelYappy(), setModalYappyQr(false), oledScreenPayments()))}>
                            Cancelar
                        </button>
                    </div>
         
                </section >

                {/*Input discunt*/}

                <input id="inputCodeYappyQr" className='vending-input-qr' ref={inputRefLogYappy} inputMode="none" type="text" onChange={(e) => handleChangeYappy(e.target.value)} ></input>


            </Modal.Body>
        </Modal>
    )
}

export default ModalYappyQr