import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { RenderIf } from '../../components/utils/RenderIf';
import { Button } from 'react-bootstrap'
import { Card } from 'react-bootstrap';
import { DivicesFechtAPI } from '../../api/Divices';
import { WhatsappFetchAPI } from '../../api/Whatsapp';
import { MachinesFetchAPI } from '../../api/Machines';
import VendingModalKeyCode from './VendingModalKeyCode';
import img_dispensed from './img/delivery.gif'
import img_Error from './img/error.gif'
import imp_yappy from './img/yappy.png'


import Swal from 'sweetalert2';

function VendingModalYappy({ showModalYappy, setShowModalYappy, selectPaymentYappy, paymentValidYappy }) {
    let token = localStorage.getItem('x-access-token');
    let machine = localStorage.getItem('machine_vending')
    let business = localStorage.getItem('business_vending')
    let branchoffice = localStorage.getItem('branchoffice_vending')


    const [stringType, setStringType] = useState("")
    const [eventHandlerKey, setEventHandlerKey] = useState(0)
    const buttons_key = [
        "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"
    ];

    const sumString = (key) => {

        let newString = stringType + key
        console.log(newString);
        setStringType(newString)
        setEventHandlerKey(1)
    }


    const handlerYappy = (e) => {
        setStringType("")

        paymentValidYappy(e, selectPaymentYappy, stringType)
        setShowModalYappy(false)


    }



    //Hook to load the initial info
    useEffect(() => {
        setEventHandlerKey(0)
    }, [eventHandlerKey]);

    return (
        <>
            {/*showModalYappy  */}
            <Modal
                show={showModalYappy}
                size='lg'
                centered
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body>
                    <div>
                        <div className='d-flex alight-item-center justify-content-center m-1 my-lg-4'>
                            <img src={imp_yappy} className='help-img'></img>
                        </div>
                        <div className='text-center'>
                            <span className=' fs-3 '>Ingresa tu número de Yappy, por favor</span>

                        </div>
                        <div className='row bg-light p-2'>
                            <div className="col-12 ">
                                <h2 className='text-center display-1 fw-bold py-3'>{stringType == "" ? <>6000-0000</> : <>{stringType}</>}</h2>
                            </div>
                        </div>

                        <div className='row'>
                            {buttons_key.map((button) => (
                                <div className='col-4  mt-4'>
                                    <div className='border-vending-secondary '
                                        onClick={() => (sumString(button))}>
                                        <h3 className='text-center text-dark fw-bold  px-lg-5 py-lg-4 px-5 py-2'>{button}</h3>
                                    </div>
                                </div>
                            ))}
                            <div className='col-8 mt-4'>
                                <div className='border-vending-secondary '
                                    onClick={() => (setStringType(""), setEventHandlerKey(1))} >
                                    <h3 className='text-center text-dark fw-bold px-lg-5 py-lg-4 px-5 py-2'>Borrar</h3>
                                </div>
                            </div>
                        </div>
                        {/* 
                        <div className='d-flex justify-content-center m-5'>
                            <button className='btn btn-primary btn-lg m-1'
                                onClick={() => (setShowModalYappy(false), setStringType(""))} >
                                Cerrar
                            </button>
                            <button className='btn btn-success btn-lg m-1' onClick={(e) => (handlerYappy(e))} >
                                Enviar
                            </button>
                        </div> */}

                        <div className='d-flex justify-content-between my-5'>
                            {/* <button className='btn  btn-lg m-1 btn-vending btn-vending-danger'
                                onClick={async () => (setShowModalSupport(false), setStringType(""))} >
                                <i className="uil uil-times-circle"></i>  Cerrar
                            </button> */}
                            <button className='border-vending-secondary fs-2 fw-bold px-5 py-2 text-dark'
                                onClick={() => (setShowModalYappy(false), setStringType(""))} >
                                Cerrar
                            </button>
                            <button className='border-vending-success fs-2 fw-bold px-5 py-2' onClick={(e) => (handlerYappy(e))} >
                                Enviar
                            </button>
                            {/* <button className='btn btn-success btn-lg m-1 btn-vending btn-vending-success'
                                onClick={async () => (sendNotification(), setStringType(""))} >
                                <i className="uil uil-message"></i> Enviar
                            </button> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default VendingModalYappy