import React, { useState, useEffect } from 'react'
import { PositionFetchAPI } from '../../api/Position';
import { GroupsFechtAPI } from '../../api/Groups'
import { Button, Modal, Row, Col } from 'react-bootstrap';
import stand from '../../assets/img/vending/stand.png'
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { MachinesFetchAPI } from '../../api/Machines';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import { RenderIf } from '../../components/utils/RenderIf';
import { VendingFetchAPI } from '../../api/LocalRequest/Vending'
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import empty from '../../assets/img/vending/empty.png'
import Swal from 'sweetalert2';

import { VendingSnakkyFetchAPI } from '../../api/LocalRequest/VendingSnakky';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css'; // Asegúrate de importar los estilos



function ModalVendingKeyboardControl({ showModalKeyboardControl, setShowModalKeyboardControl, maxString, placeHolderInput }) {

    const [displayNumber, setDisplayNumber] = useState('');
    const handleNumberClick = (number) => {
        //key press

        setKey(number)
        /*
        
                if (maxString == undefined) {
                    setDisplayNumber((prevDisplayNumber) => prevDisplayNumber + number);
                } else {
                    if (displayNumber.length < maxString) {
                        setDisplayNumber((prevDisplayNumber) => prevDisplayNumber + number);
                    }
                }*/
    };

    const handleClearClick = () => {
        setDisplayNumber('');
    };


    const setKey = async (key) => {
        let data = [{ "vendingCode": "1," + key}]
        try {
            const response = await VendingSnakkyFetchAPI.keypad(data)
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <>

            {/*Modification temp*/}
            <Modal
                show={showModalKeyboardControl}
                size='lg'
                onHide={() => setShowModalKeyboardControl(false)}
                centered
                aria-labelledby="example-custom-modal-styling-title"

            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>

                    <div>
                        <div className='keyboard-main'>
                            <div className='keyboard-pad d-flex flex-wrap'>
                                <input type="text"

                                    className='keypadPrimary-input_1  form-control'
                                    placeholder={placeHolderInput == undefined ? 'Input' : placeHolderInput} value={displayNumber} readOnly
                                    maxLength="4"
                                />
                                <button className='num  kiosk-key-letter' onClick={() => handleNumberClick('1')}>1</button>
                                <button className='num  kiosk-key-letter ' onClick={() => handleNumberClick('2')}>2</button>
                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('3')}>3</button>

                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('4')}>4</button>
                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('5')}>5</button>
                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('6')}>6</button>

                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('7')}>7</button>
                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('8')}>8</button>
                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('9')}>9</button>

                                <button className='num h6 text-muted border-0 visually-hidden'
                                ></button>
                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('0')}>0</button>
                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('E')}>E</button>
                                <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('C')}>C</button>

                            </div>

                            <div className='d-flex justify-content-center alight-item-center'>
                                <button className='btn btn-success btn-lg mt-1 px-5 btn-vending btn-vending-success ' onClick={() => setKey('12')} >
                                    <i className="uil uil-setting  display-1"></i>
                                    <span className='display-1 fw-bold'> Operador </span>
                                </button>
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModalVendingKeyboardControl