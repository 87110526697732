import React, { useState, useEffect } from 'react'
import VendingModalQr from '../layouts/Vending/VendingModalQrInit'
import VendingMenu from '../layouts/Vending/VendingMenu'
import { RenderIf } from '../components/utils/RenderIf';
import '../assets/css/vending.css';

function Vending() {

  const [showComponent, setShowComponent] = useState("Menu");
  const [eventHandler, setEventHandler] = useState(0);
  const [showModalKeyCode, setShowModalKeyCode] = useState(false)


  const handleContextMenu = (e) => {
    e.preventDefault(); // Evita que aparezca el menú contextual
  };

  const handleMouseDown = (e) => {
    if (e.button === 2) {
      e.preventDefault(); // Evita que el clic derecho dispare el menú contextual
    }
  };

  //Hook to load the initial info
  useEffect(() => {
    (async () => {
      let token = localStorage.getItem("x-access-token");
      //We check if the user has the token 
      if (token == null || token == '') {
        console.log("Login");
        setShowComponent("Login")
        setEventHandler(1)
      }else{
        console.log("Menu");
        setShowComponent("Menu")
        setEventHandler(1)
      }
      
    })();
    setEventHandler(0)
  }, [eventHandler]);

  return (
    <>
      <div className='m-0 p-0'  onContextMenu={(e) =>handleContextMenu(e)} onMouseDown={(e) =>handleMouseDown(e)}>
        <RenderIf isTrue={showComponent == "Menu"}>
          <VendingMenu setEventHandler={setEventHandler} showModalKeyCode={showModalKeyCode} setShowModalKeyCode={setShowModalKeyCode} />
        </RenderIf>

        <RenderIf isTrue={showComponent == "Login"}>
          <VendingModalQr setEventHandler={setEventHandler} showModalKeyCode={showModalKeyCode} setShowModalKeyCode={setShowModalKeyCode} />
        </RenderIf>
      </div>
    </>
  )
}

export default Vending