import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { RenderIf } from '../../components/utils/RenderIf';
import { Button } from 'react-bootstrap'
import { Card } from 'react-bootstrap';
import { DivicesFechtAPI } from '../../api/Divices';
import { WhatsappFetchAPI } from '../../api/Whatsapp';
import { MachinesFetchAPI } from '../../api/Machines';
import VendingModalKeyCode from './VendingModalKeyCode';
import img_dispensed from './img/delivery.gif'
import img_Error from './img/error.gif'
import img_support from './img/support.gif'
import img_OutService from './img/outService.png'


import Swal from 'sweetalert2';

function Vendingoutservice({ showModaloutService, setShowModaloutService, setEventHandlerMenu, clearShoppingCart, status }) {
    let token = localStorage.getItem('x-access-token');
    let machine = localStorage.getItem('machine_vending')
    let business = localStorage.getItem('business_vending')
    let branchoffice = localStorage.getItem('branchoffice_vending')

    const [eventHandlerKey, setEventHandlerKey] = useState(0)

    if(showModaloutService == true){
        setTimeout(async() => {
            sendMessage()
        }, 20000);
    }

    const sendMessage = async () => {
        LoadingAlert("Enviando mensaje")
        machine = JSON.parse(localStorage.getItem('machine_vending'))
        branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
        business = JSON.parse(localStorage.getItem('business_vending'))


        try {
            const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token);

            let data = {
                "notification_type": "smartpay_notification",
                "branchoffice_id": branchoffice.id,
                "business_id": business.id,
                "phone_to_send": branchoffice.branchoffice_support_phone,
                "header_text": "Error de comunicacion",
                "message_body": [{ "text": "maquina vending: " + response_machine.data.data.machine_name + ", codigo de maquina: " + machine.code + ", de la empresa" + business.name +". Ocurrio un error de comunicacion" }, { "text": "validar comunicacion de la vending" }]
            }


            console.log(data);
            const response = await WhatsappFetchAPI.sendNotificationWhatsapp(data, token)
            console.log(response);

            if (status == 1) {
                clearShoppingCart(true)
            }

            setEventHandlerMenu(1)
            setShowModaloutService(false)
            ErrorAlert("Solicitud de soporte enviada", "Envio exitoso", "success")



        } catch (err) {
            console.log(err);
        }
    }



    //Hook to load the initial info
    useEffect(() => {
        setEventHandlerKey(0)
    }, [eventHandlerKey]);

    return (
        <>
            <Modal
                show={showModaloutService}
                size='lg'
                centered
                onHide={() => setShowModaloutService(false)}
            >
                <Modal.Body>
                    <div className='d-flex alight-item-center justify-content-center'>
                        <section className='mx-2'>
                            <div className='d-flex justify-content-center m-0'>
                                <img className='w-50 h-100' src={img_OutService} alt='Help Img' />
                            </div>

                            <div>
                                <div className='vendindg-title'>
                                    <h2 className='text-center text-title-help fw-bold'>Lo sentimos, esta maquina esta fuera de servicio</h2>
                                </div>
                                <div>
                                    <div className='m-4 vendindg-message alert alert-light'>
                                        <h3 className='text-center'>Pronto volvera al trabajo.</h3>
                                    </div>


                                    <div className='d-flex justify-content-between my-5'>
                                        <button className='btn  btn-lg m-1 btn-vending btn-vending-danger'
                                            onClick={async () => sendMessage()} >
                                            <i className="uil uil-times-circle"></i>  Cerrar
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Modal.Body>
            </Modal>



        </>
    )
}

export default Vendingoutservice