import React from 'react';

const PagueloFacil = () => {
    return (
            <div align="center">
                <div id="container-form" style={{width: "45%"}}>
                    <a href="https://sandbox.paguelofacil.com/LinkDeamon.cfm?CCLW=E6D0BDE4DF69DFE61042A43374E9500CE8DABAA6AB8A8C083F5F783208E60CB1ECE420AB3C60533063BE5F952E49742705C0ADB8736C6888E0B3B9FC60FAA822&CMTN=1&CDSC=Descripcion"><img src="https://secure.paguelofacil.com/images/botones/BotonSeleccionado.png"  /></a>
                </div>
            </div>
    );
}

export default PagueloFacil;
