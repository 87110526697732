import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

//Components
import LandingPage from './components/_index';
import NoMatch from "./components/layout/NoMatch";


//Vending 
import Vending from './pages/Vending.jsx'
//API 
import { AuthFetchAPI } from "./api/Auth";

//context 
import UserContext from "./context/UserContext";


//CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/js/bootstrap.esm'
import './App.css';
import './index.css';
//Icons
import 'bootstrap-icons/font/bootstrap-icons.css'




///auth/token


function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined
  });
  const checkLogin = async () => {
    let token = localStorage.getItem('x-access-token');

    //esta variable sme ayuda a saber la url actual y separarlas por / para saber su posicion actual
    let urlActual = window.location.href.split('/')
    let posicionUrl = urlActual[(urlActual.length - 2)]




    //We check if the user has the token 
    if (token == null) {
      localStorage.setItem('x-access-token', '');
      token = ''
    }/*else if(posicionUrl == "login" && token != ''){
      window.location.href = "/request_pay";
    }else if(token == '' && posicionUrl != "login"){
      window.location.href = "/login";
    }*/

    try {
      const tokenres = await AuthFetchAPI.validtoken(token);
      // console.log(tokenres)
      if (tokenres.data === true) {

        const user = await AuthFetchAPI.me(token);
        setUserData({ token: token, user: user.data })
      }
    } catch (error) {
      window.location.href = "/login";
    }
  }

  useEffect(() => {
    (async () => {
      try {
        // await checkLogin()
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  return (
    <>
      <Router>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Routes>
            {/* Index Main Menu  */}
            <Route exact path="/" element={<LandingPage />} />
            {/*Vending */}
            <Route path="/vending" element={<Vending />}></Route>

            {/* No Match link */}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </UserContext.Provider>
      </Router>
    </>
  );
}

export default App;
