import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { View } from '@react-pdf/renderer';
import videoInit from './videos/videoGeneric.mp4'
import { PublicityFechtAPI } from '../../api/Publicity';

function VendingIdel({ showModalIdel, setShowModalIdel, publicity, video, inputRefMenu, videoNow, setVideoNow}) {

    let token = localStorage.getItem("x-access-token");
    let machine = localStorage.getItem('machine_vending');

    const inputIdel = useRef(null)
    const [handlerchange, setHandlerchange] = useState(0)
    
    const [turn, setTurn] = useState(0)


    if (machine != undefined && machine != "") {
        machine = JSON.parse(localStorage.getItem('machine_vending'))
    }


    let activity = 0

    useEffect(() => {
        (async () => {

        })()
    }, []);


    const handlerChange=(txt) =>{console.log(txt);
        setShowModalIdel(false)
    
        inputRefMenu.current.focus()
        inputRefMenu.current.value=txt
    }

    //timer activity and time


    useEffect(() => {
        const interval = setInterval(() => {

            console.log(video);
            if (turn <= video.length) {
                setTurn(turn + 1)
                setVideoNow(video[turn].video_url)
            } else {
                setTurn(0)
                setVideoNow(video[0].video_url)
            }

          

            //setHandlerchange(1)
            //console.log("video " + activity);
        }, 15000); // Ejecutar cada 1000 ms (1 segundo)


        return () => {
            clearInterval(interval); // Limpiar el intervalo al desmontar el componente
        };
    }, [video, turn]);

    useEffect(() => {
        setTimeout(() => {
            inputIdel.current.focus()
        }, 2000);

    }, [showModalIdel]);


    return (
        <div>
            {/* showModalIdel */}
            <Modal
                show={showModalIdel}
                fullscreen
                onHide={() => setShowModalIdel(false)}
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body className='vh-100'>
                    <main className='h-100  bg-black' onClick={() => setShowModalIdel(false)}>
                        <video 
                        className='h-100 w-100'
                        src={videoNow} 
                        type="video/mp4" autoPlay={"autoplay"} muted loop 
                        onClick={() => setShowModalIdel(false)} ></video>
                    </main>

                        {/* <View style={{ flex: 1 }}>
                        <View style={{ borderWidth: 1, position: 'absolute', bottom: 0, left: 280, alignSelf: 'flex-end' }}>
                           <div className='d-flex justify-content-center alight-item-center'>
                                <Button className='btn btn-success btn-lg mt-1 px-5 btn-vending btn-vending-success ' onClick={() => { setShowModalIdel(false) }}><i className="uil uil-shop"></i> COMPRAR AQUI</Button>
                            </div>
                        </View>
                    </View>*/}
                   

                    <div className='position-absolute '>
                        <input id="inputIdel" ref={inputIdel} type='text' inputMode="none" onChange={(e) => handlerChange(e.target.value)} className='vending-input-qr' autoFocus></input>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default VendingIdel