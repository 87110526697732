import React, { useState, useEffect, useRef } from 'react'
import qr_img from '../../assets/img/vending/qr.png'
import VendingModalKeyCode from './VendingModalKeyCode'
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';

//IMG
import scanQR2 from './img/scanyourqr.gif'
import Logo from '../../assets/img/logos/logo.png'
import scanQR3 from './img/icons/escaneo-qr.png'
import keyboard from './img/icons/teclado.png'

//api
import { AuthFetchAPI } from '../../api/Auth';

//css
import "./css/vending.login.css"


function VendingModalQr({ setEventHandler, showModalKeyCode, setShowModalKeyCode }) {
   const [codeString, setCodeString] = useState("")
   const [codeSesion, setCodeSesion] = useState({
      business_code: "0000",
      branchoffice_code: "0000",
      machine_code: "0000",
   });

   const inputRefLog = useRef(null);
   setTimeout(() => {
      inputRefLog.current.focus()
   }, 3000);

   //reload the page
   const reload = () => {
      window.location.reload()
   }

   const authMachineVending = async () => {
      try {
         const response = await AuthFetchAPI.authMachine(codeSesion.business_code, codeSesion.branchoffice_code, codeSesion.machine_code);
         console.log(response);
         SuccessAlert()

         //save data auth in localStorage
         localStorage.setItem('x-access-token', response.data.token);
         localStorage.setItem('business_vending', JSON.stringify(response.data.business));
         localStorage.setItem('branchoffice_vending', JSON.stringify(response.data.branchoffice));
         localStorage.setItem('machine_vending', JSON.stringify(response.data.machine));
         localStorage.setItem('positions', "[]");
         setShowModalKeyCode(false)
         //interval of reload the page
         setInterval(reload, 2000)
      } catch (err) {

         setCodeSesion({
            business_code: "0000",
            branchoffice_code: "0000",
            machine_code: "0000",
         });

         ErrorAlert('', err.response.data.error, 'error')
         document.getElementById("inputCodeQr").value = ""
         // message, title, icon
         console.log(err);
      }
   }

   const handleChange = (e) => {

      if (e.key === 'Enter') {
         LoadingAlert()
         let inputValue = e.target.value
         console.log(inputValue);

         let arrayCodeString = inputValue.split("X");
         console.log(arrayCodeString);

         codeSesion.business_code = arrayCodeString[3]
         codeSesion.branchoffice_code = arrayCodeString[2]
         codeSesion.machine_code = arrayCodeString[1]
         setCodeSesion(codeSesion)
         authMachineVending()

      }
   }
   return (
      <>

         <main className='login-kiosk bg-light ' onClick={() => (inputRefLog.current.focus())}>
            <div className='container h-100'>
               <div className='d-flex justify-content-center'>
                  <img className='login-kiosk-img' src={Logo} alt="Logo Smart Pay" />
               </div>

               <div className='login-kiosk-header text-center'>
                  <h2 className='mb-0'>
                     Inicio de
                     <span className='brand-smart-pay-text'> Sesión </span>
                  </h2>
               </div >

               <section className='login-kiosk-content border border-2 rounded-4 p-lg-5 p-2 shadow-sm position-relative bg-white'>
                  {/* <div class="position-absolute  login-kiosk-content-number ">1</div> */}
                  <h3 className=' text-center '> Escanner QR </h3>
                  <div className="d-flex justify-content-center ">
                     <img src={scanQR3} alt="QR Img" className='login-kiosk-qr' loading='lazy' />
                  </div>
                  <p className='text-muted'>
                     <i class="uil uil-info-circle me-2"></i>
                     Validación de kiosko, por favor, coloque el código QR en el escáner de la máquina.
                  </p>
               </section>
               <p className='text-center login-kiosk-text-alt text-muted'>O puede utilizar</p>

               <section className='login-kiosk-content border border-2 rounded-4 p-lg-5 p-2 shadow-sm position-relative bg-white'>
                  {/* <div class="position-absolute  login-kiosk-content-number ">1</div> */}
                  <h3 className=' text-center '> Códigos de Validación </h3>
                  <div className="d-flex justify-content-center ">
                     <img src={keyboard} alt="QR Img" className='login-kiosk-key' loading='lazy' />
                  </div>
                  <div className="d-flex justify-content-center ">
                     <button type="button"
                        className="btn-vending-success  mt-1 w-100  border-2 login-kiosk-content-text rounded-4 py-lg-3 py-1"
                        onClick={() => (setShowModalKeyCode(true))}>
                        Ingresar Código
                     </button>
                  </div>

                  <p className='text-muted'>
                     <i class="uil uil-info-circle me-2"></i>
                     Validación por código, presione <b> Ingresar código </b>
                     e introduzca los códigos correspondientes en la máquina.
                  </p>
               </section>
            </div>
            {/* 
            <div className="d-flex align-items-center flex-column bd-highlight">
               <div className='container  mt-5'>
                  <div className='vending-button bd-highlight'>
                     <div className="d-flex justify-content-center">
                        <div className='vending-button-content'>
                           <h2 className="fw-bold mb-1 text-center button-sub-title ">O utilice  </h2>
                           <button type="button" className="btn btn-lg mt-1 w-100 btn-other border-0 text-primary "
                              onClick={() => (setShowModalKeyCode(true))}>
                              Ingresar el código de forma manual
                           </button>
                        </div>
                     </div>
                  </div>

               </div>

            </div> */}
         </main>
         <div className='position-absolute'>
            <input id="inputCodeQr" className='vending-input-qr' ref={inputRefLog} inputMode="none" type="text" onKeyPress={(e) => handleChange(e)} autoFocus></input>

         </div>
         <VendingModalKeyCode
            codeSesion={codeSesion}
            setCodeSesion={setCodeSesion}
            authMachineVending={authMachineVending}
            showModalKeyCode={showModalKeyCode}
            setShowModalKeyCode={setShowModalKeyCode}
         />


         {/* <div className="position-absolute bottom-0 end-0 FW-BOLD  opacity-25">
            <h2 className='px-2'>
               Powered By Smart Retail Group
            </h2>
         </div> */}
      </>
   )
}

export default VendingModalQr