import React, { useState, useEffect } from 'react'
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import stand from '../../assets/img/vending/stand.png'
import { RenderIf } from '../../components/utils/RenderIf';
import VendingModalCart from './VendingModalCart';
import Vendingoutservice from './Vendingoutservice';
import { Modal } from 'react-bootstrap';
import { BiCartDownload } from 'react-icons/bi';
import { OrderNumberFetchAPI } from '../../api/Paymentpost/OrderNumber';
function VendingModalProduct({ selectGroup, setShoppingCart, shoppingCart, showModalProduct, setShowModalProduct, setShowModalShopingCart, setEventHandlerMenu, showModalShopingCart, dataClient, setDataClient, groups, setSelectGroup, getGroupById, showModalPayment, setShowModalPayment, setStatusScreen, statusScreen, getTemp, statusComunicationVending, amount, setAmount,
  showModaloutService, setShowModaloutService, orderNumber, setOrderNumber, addProduct, getOrderNumber, payments, setPayments, oledScreenPayments, setStatusTempTurnNow, setAtivityTimer
}) {
  //const [showModalPayment, setShowModalPayment] = useState(false);
  const [eventHandler, setEventHandler] = useState(0)
  const [eventHandlerLoader, setEventHandlerLoader] = useState(0)
  let business = localStorage.getItem('business_vending');
  let businessCode = ""

  if (business != null && business != '' && business != undefined) {
    business = JSON.parse(localStorage.getItem('business_vending'));
    businessCode = business.code
    console.log(business.code)
  }

  //console.log(selectGroup);
  //increment amount
  const buttonPlus = () => setAmount(amount + 1);
  //decrement amount
  const buttonDecrease = () => {
    if (amount > 1) {
      setAmount(amount - 1)
    }
  }


  //Next Product
  const nextProduct = () => {

    let index = selectGroup.index
    const array = groups.filter(producto => producto.name !== null && producto.amount > 0);

    const productNoNulls = [];

    array.forEach(p => {
      if (productNoNulls.findIndex(item => item.group_id === p.group_id) === -1) {
        // No existe; al detectar que no existe el mismo nombre, "la copiamos"
        productNoNulls.push(p);
      }
    });

    ordenarAsc(productNoNulls, 'positionX')
    let nextIndex = (index + 1)

    getGroupById(productNoNulls[nextIndex].group_id, productNoNulls[nextIndex].positionX, productNoNulls[nextIndex].positionY, productNoNulls[nextIndex].amount, productNoNulls[nextIndex].position_id, nextIndex, productNoNulls[nextIndex].detail_group)

    setEventHandlerLoader(1);
  }


  function ordenarAsc(p_array_json, p_key) {
    let newArray = p_array_json.sort(function (a, b) {
      return a[p_key] > b[p_key];
    });


    return (newArray.reverse())
  }


  const afterProduct = () => {

    let index = selectGroup.index
    const array = groups.filter(producto => producto.name !== null && producto.amount > 0);

    const productNoNulls = [];

    array.forEach(p => {
      if (productNoNulls.findIndex(item => item.group_id === p.group_id) === -1) {
        // No existe; al detectar que no existe el mismo nombre, "la copiamos"
        productNoNulls.push(p);
      }
    });

    ordenarAsc(productNoNulls, 'positionX')
    let nextIndex = (index - 1)
    getGroupById(productNoNulls[nextIndex].group_id, productNoNulls[nextIndex].positionX, productNoNulls[nextIndex].positionY, productNoNulls[nextIndex].amount, productNoNulls[nextIndex].position_id, nextIndex, productNoNulls[nextIndex].detail_group)

    setEventHandlerLoader(1);
  }


  //Hook to recharge the component
  useEffect(() => {
    setShoppingCart([...shoppingCart]);
    setEventHandler(0);

  }, [eventHandler]);

  //Hook to recharge the component
  useEffect(() => {

    setEventHandlerLoader(0);
  }, [eventHandlerLoader]);

  return (
    <>
      <Modal
        show={showModalProduct}
        size='lg'
        // fullscreen
        onHide={() => setShowModalProduct(false)}
        centered

      >
        <Modal.Body className='m-0 p-0 d-flex align-content-center justify-content-center align-items-center'>
          <div className='vending' onClick={() => setAtivityTimer(7)}>
            <h2 className='text-center fw-bold mb-0 title-modal-categories pt-2'>
              Producto Seleccionado
            </h2>

            <div className='product-stand position-relative '>
              {/* <img src={stand} className=' background-image img-stand' alt="Stand" /> */}
              <div className='d-flex justify-content-center'>
                <img src={selectGroup.img} className='foreground-image  img-modal-product border-vending-secondary' alt="" />
              </div>

              <div className="buttons-steps w-100">
                <div className='d-flex justify-content-between p-1 mx-1  '>
                  <button className="  btn-menu-vending pulse" onClick={() => (afterProduct())}>
                    <span className='text-center '>
                      <i class="uil uil-angle-left-b"></i>
                    </span>
                  </button>
                  <button className=" btn-menu-vending pulse" onClick={() => (nextProduct())}>
                    <span className='text-centert'>
                      <i class="uil uil-angle-right-b"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className=' mx-4 mb-4 mt-lg-5'>
              <h3 className='text-center modal-title-product'>
                {selectGroup.name}
              </h3>
              <div>
                <h4 className='text-center modal-price-product '>
                  $ {(parseFloat(selectGroup.price) - parseFloat(selectGroup.product_discount)).toFixed(2)}
                </h4>
              </div>

              <div className='d-flex justify-content-center p-2'>
                {/* <button className='btn btn-primary btn-vending-product btn-menu-vending mx-3' onClick={() => { setShowModalProduct(false) }}><i className="uil uil-arrow-left"></i> Volver </button> */}
                <RenderIf isTrue={selectGroup.stock > 0}>
                  <button className=' btn-success-send btn-lg px-5 rounded-3 border-vending-success' onClick={() => (addProduct())} >
                    <i className="uil uil-shopping-cart-alt  "></i>
                    <span className='fw-bold'> Comprar </span>
                  </button>
                </RenderIf>
                <RenderIf isTrue={selectGroup.stock == 0 || selectGroup.stock < 0}>
                  <button type="button" className="btn btn-outline-secondary btn-vending-product btn-menu-vending mx-3 disabled">
                    <i className="uil uil-sad-squint"></i> Agotado</button>
                </RenderIf>
              </div>
            </div>

            <div className="mt-5 " hidden>
              <span className='px-1 number-counter'>
                <button className="round-button" onClick={() => (buttonDecrease())}>
                  <span className="minus"></span>
                </button>
                <p>{amount}</p>
                <button className="round-button" onClick={() => (buttonPlus())}>
                  <span className="plus"></span>
                </button>
              </span>
            </div>


          </div>
          <div className='d-flex justify-content-start p-1 position-absolute start-0 bottom-0 m-1'>
            <button
              title="Volver al menú"
              className='btn-return-vending pulse   '
              onClick={() => { setShowModalProduct(false); setStatusScreen({ screen: "menu" }); setShoppingCart([]); setStatusTempTurnNow(true) }}

            >
              <span className=' fw-bold'>
                <i className="uil uil-arrow-left icon-button"></i> 
              </span>
            </button>
          </div>
        </Modal.Body>

      </Modal>



      {/*----------------------------------------*/}
      <VendingModalCart setShoppingCart={setShoppingCart} shoppingCart={shoppingCart} showModalShopingCart={showModalShopingCart}
        setShowModalShopingCart={setShowModalShopingCart} eventHandler={eventHandler} dataClient={dataClient}
        setDataClient={setDataClient} showModalPayment={showModalPayment} setShowModalPayment={setShowModalPayment}
        setEventHandlerMenu={setEventHandlerMenu} setStatusScreen={setStatusScreen} statusComunicationVending={statusComunicationVending}
        groups={groups} orderNumber={orderNumber} setOrderNumber={setOrderNumber} payments={payments} setPayments={setPayments}
        oledScreenPayments={oledScreenPayments} setAtivityTimer={setAtivityTimer}/>


      <Vendingoutservice showModaloutService={showModaloutService} setShowModaloutService={setShowModaloutService} setEventHandlerMenu={setEventHandlerMenu} clearShoppingCart status={0} setAtivityTimer={setAtivityTimer}></Vendingoutservice>


    </>
  )
}

export default VendingModalProduct