import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { RenderIf } from '../../components/utils/RenderIf';
import { Button } from 'react-bootstrap'
import { Card } from 'react-bootstrap';
import { DivicesFechtAPI } from '../../api/Divices';
import { MachinesFetchAPI } from '../../api/Machines';
import VendingModalKeyCode from './VendingModalKeyCode';
import VendingModalSupport from './VendingModalSupport';
import VendingModalVoucher from './VendingModalVoucher';
import img_dispensed from './img/delivery.gif'
import QRCode from "react-qr-code";
import img_Error from './img/error.gif'
import img_support from './img/support.gif'
import img_check from './img/check.gif'
import { VendingSnakkyFetchAPI } from '../../api/LocalRequest/VendingSnakky';
import oledComunication from './OledComunication';

import { useWindowSize } from '../../hooks/useWindowSize';
//* Gifs
import gif_1 from "./img/gif/gif-1.gif"
import gif_2 from "./img/gif/gif-2.gif"
import Swal from 'sweetalert2';

function VendingDispensed({ itemReadyDispense, showModalDispense, setShowModalDispense, ready, statusError, setStatusError, voucher_Order, dataInfoPaymentSelect, setEventHandlerMenu, order_code_register, clearShoppingCart, voidCreditDebit, dataNoteCredit, setDataRutaQr, dataRutaQr, shoppingCart, registerOrderTempReturn, inputRefLogDispensed, oledSupport, setAtivityTimer }) {
  let token = localStorage.getItem('x-access-token');
  let machine = JSON.parse(localStorage.getItem('machine_vending'));

  const inputRefSupport = useRef(null);
  const [contentSupport, setContentSupport] = useState({ title: "", body: [{ text: "" }, { text: "" }] })
  const [showModalvoucher, setshowModalvoucher] = useState(false);
  const [showModalSupport, setShowModalSupport] = useState(false);
  let routeQr = ""
  let branchoffice = ""



  if (localStorage.getItem('branchoffice_vending')) {
    if (localStorage.getItem('branchoffice_vending') != null || localStorage.getItem('branchoffice_vending') != '') {
      branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
      //routeQr = "192.168.1.12:3000/order/" + order_code_register + "/branchoffice/" + branchoffice.id
      routeQr = "https://smartpay.ai/order/" + order_code_register + "/branchoffice/" + branchoffice.id

    }
  }



  const viewItemreadyDispense = () => {
    const listDispense = itemReadyDispense.map((item) => (
      <>
        {/* <div className='rounded border p-3 m-3' >
          <div className='d-flex  justify-content-between '>
            <img src={item.img} className='border rounded-4' height="400px" width="400px"></img>
            <div className='m-3'>
              <h2 className='text-center text-msg-footer'>

                {item.name}
              </h2>
              <h3 className='text-center text-msg-footer'> ${item.price}</h3>
            </div>
            {item.status == "success" ? <><i className="uil uil-check-circle icon-button-params" ></i></> : <><i className="uil uil-exclamation-triangle icon-button-params"></i></>}
          </div>
        </div> */}

        <div className={item.status == "success"
          ? 'rounded-4 border border-success border-3 p-3 m-3'
          : 'rounded-4 border border-warning border-3 p-3 m-3'}>

          <div className='d-flex  justify-content-between '>
            <img src={item.img} className='border rounded-4' height="100px" width="100px"></img>
            <div className='m-3'>
              <h2 className='text-center'>

                {item.name}
              </h2>
              <h3 className='text-center'>${item.price}</h3>
            </div>
            {item.status == "success"
              ? <><i className="uil uil-check-circle icon-button-params" ></i></>
              : <><i className="uil uil-exclamation-triangle icon-button-params"></i></>}
          </div>
        </div>
      </>
    ));
    return listDispense;
  }


  const handlerSuppor = async () => {
    setAtivityTimer(5)
    registerOrderTempReturn({ EMVStreamResponse: "0" })
    machine = JSON.parse(localStorage.getItem('machine_vending'))
    try {
      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
      console.log(response_machine);
      setContentSupport({ title: "Error de despacho", body: [{ "text": response_machine.data.data.machine_name + " no se despacho correctamente el producto en la posicion: " + "X:" + shoppingCart[0].X + ", Y:" + shoppingCart[0].Y + " de la orden: " + order_code_register }, { text: "informacion:" }] })
      setShowModalSupport(true)
      //clearShoppingCart();
      setShowModalDispense(false);

    } catch (err) {
      console.log(err);
    }
  }

  const handlerChangeInputAction = () => {

    if (dataInfoPaymentSelect != "Yappy QR" && dataInfoPaymentSelect != "Yappy" && dataInfoPaymentSelect != "Clave" && dataInfoPaymentSelect != "Credito") {
      oledReturn()
      voidCreditDebit()
    } else {

      oledInputPhone()
      clearStatussentorandlimitDigit(8, "2,1,2,15,30,")
      handlerSuppor()


    }
  }

  const oledInputPhone = () => {
    if (machine.machine_subtype_code === "VGG") {
      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0, "2,1,1,10,10,Ingresa tu numero")
      }, 50);

      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0, "2,1,1,30,20,de celular")
      }, 100);
    }
  }

  const oledReturn = () => {
    if (machine.machine_subtype_code === "VGG") {
      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0, '2,1,1,25,20,Devolucion')
      }, 50);

      setTimeout(() => {
        oledComunication(0, '2,1,1,35,30,0.En proceso.')
      }, 100);
    }
  }

  const clearStatussentorandlimitDigit = async (data, settingtxt) => {
    try {

      await VendingSnakkyFetchAPI.clearData({ "limit": data, "setting": settingtxt })

    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    if (showModalDispense == true) {

      setTimeout(() => {
        inputRefLogDispensed.current.focus()
      }, 1000);

    }
  }, [showModalDispense])


  const { width } = useWindowSize();
  const qrSize = width <= 480 ? "200" : "300";

  return (
    <>

      {/*Modal dispense item showModalDispense*/}
      <Modal
        show={showModalDispense}
        fullscreen
        onHide={() => setShowModalDispense(false)}
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Body className='h-100'>
          <div  onClick={() => (inputRefLogDispensed.current.focus(), setAtivityTimer(5))}>
            <RenderIf isTrue={ready == true}>
              <section className='section-thanks h-100'>
                <div className='d-flex  align-items-center h-100 mensaje'>
                  <div>
                    <h1 className='text-msg-venidng  fw-bold my-5 text-center'>
                      ¡Gracias por comprar, vuelve pronto!
                    </h1>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <img src={gif_1} className=' img-gif rounded-4 border-vending-primary' />
                </div>
                <div className="position-relative fw-bold  opacity-75 text-center border-vending-secondary mt-5">
                  <small className='text-secondary text-msg-footer'> Powered By</small>
                  <h2 className='px-2 fw-bold text-dark text-msg-footer'>
                    Smart Retail Group
                  </h2>
                </div>
              </section>
            </RenderIf>


            <RenderIf isTrue={statusError != true}>
              <div className='d-flex  justify-content-center align-items-center h-100 mt-lg-5 mt-0'>
                {itemReadyDispense.length == 0 ? <>
                  <section>
                    <h2 className='text-center text-msg-venidng  fw-bold my-2 text-uppercase'>Despachando  <br /> Productos</h2>
                    <img src={img_dispensed} className='img-dispensed mt-2' title='Mood Dispensed' />

                    <div className="position-relative fw-bold  opacity-75 text-center border rounded-4 mt-5">
                      <small className='text-secondary text-msg-footer'> Powered By</small>
                      <h2 className='px-2 fw-bold text-dark text-msg-footer'>
                        Smart Retail Group
                      </h2>
                    </div>
                  </section>
                </> : <>
                  <section>
                    <h2 className='text-center text-msg-venidng fw-bold my-2 '> Productos Despachados Correctamente</h2>
                    <div className='m-3 p-3'>
                      {viewItemreadyDispense()}

                    </div>
                  </section>
                </>}
              </div>
            </RenderIf>


            <RenderIf isTrue={statusError == true}>
              <div className=' flex-column  h-100'>
                <div className='w-100' >
                  <RenderIf isTrue={dataNoteCredit == true}>
                    <div className='m-4'>
                      <h4 className='text-center text-msg-footer fw-bold'>Detalle de la devolución</h4>
                    </div>
                  </RenderIf>
                  <div className="m-4  d-flex justify-content-center">
                    <QRCode value={routeQr} size={qrSize} bgColor="#282c34" fgColor="#fff" level="H" />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <RenderIf isTrue={dataNoteCredit == true}>
                        <div className='d-flex justify-content-center'>
                          <img src={img_check} width={'200px'} />
                        </div>
                        <div className='d-flex justify-content-center'>
                          <h1 className='text-center text-msg-footer fw-bold my-5'>Se realizó la  devolución correctamente.</h1>
                        </div>
                      </RenderIf>

                      <RenderIf isTrue={dataNoteCredit == false}>
                        <div className='d-flex justify-content-center'>
                          {/* <img src={img_Error} width={'200px'} /> */}
                          <img src={gif_2} className=' img-gif rounded-4 border-vending-primary' />
                        </div>
                        <div className='d-flex justify-content-center'>

                          <h2 className='text-center text-msg-footer fw-bold my-1 text-danger'>Ocurrio un error en el despacho</h2>
                        </div>
                      </RenderIf>

                    </Card.Title>
                    <Card.Text>
                      <RenderIf isTrue={dataNoteCredit == true}>
                        <div className='m-4'>
                          <h5 className='text-center text-muted text-msg-footer fw-bold my-1'>Puedes escanear el QR, toma una foto para conservar tu comprobante.</h5>
                        </div>
                      </RenderIf>

                      <RenderIf isTrue={dataNoteCredit == false}>
                        <div className='m-4'>
                          <h5 className='text-center text-msg-footer fw-bold my-1'>Se realizara la  devolución del monto pagado.</h5>
                        </div>
                      </RenderIf>
                      <RenderIf isTrue={voucher_Order != "0"}>
                        <hr></hr>
                        <p className='text-center text-msg-footer fw-bold my-5'> Método de pago: {dataInfoPaymentSelect}</p>
                        <p className='text-center text-msg-footer fw-bold my-5'>Recibo de pago:</p>
                        <VendingModalVoucher
                          showModalvoucher={showModalvoucher}
                          setshowModalvoucher={setshowModalvoucher}
                          voucher_Order={voucher_Order}>
                        </VendingModalVoucher>

                      </RenderIf>
                    </Card.Text>


                    <RenderIf isTrue={dataNoteCredit == false}>
                      <div className='d-flex alight-item-center justify-content-center'>
                        <button className='border-vending-primary p-3 fw-bold' onClick={async () => { dataInfoPaymentSelect != "Yappy QR" && dataInfoPaymentSelect != "Yappy" && dataInfoPaymentSelect != "Clave" && dataInfoPaymentSelect != "Credito" ? voidCreditDebit() : handlerSuppor() }}>Continuar para solicitar  devolución</button>
                      </div>
                    </RenderIf>

                    <RenderIf isTrue={dataNoteCredit == true}>
                      <div className='d-flex justify-content-start'>
                        <button className='border-vending-danger p-3 fw-bold' onClick={async () => (clearShoppingCart(true))}>Cerrar</button>
                      </div>
                    </RenderIf>

                  </Card.Body>
                </div>
              </div>
            </RenderIf>

            <input id="inputDispence" className='vending-input-qr' ref={inputRefLogDispensed} inputMode="none" type="text" onChange={() => handlerChangeInputAction()} autoFocus></input>
          </div>
        </Modal.Body>
      </Modal >





      <VendingModalSupport showModalSupport={showModalSupport} setShowModalSupport={setShowModalSupport} contentSupport={contentSupport} setEventHandlerMenu={setEventHandlerMenu} suportReturn={true} inputRefSupport={inputRefSupport} setAtivityTimer={setAtivityTimer} ></VendingModalSupport>

    </>
  )
}

export default VendingDispensed