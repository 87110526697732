import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import Keyboard from '../../components/Keyboard/Keyboard';
import VendingDispensed from './VendingDispensed';
import Vendingoutservice from './Vendingoutservice';
import VendingModalConfirmation from './VendingModalConfirmation';
import { Button } from 'react-bootstrap'
import { PaymentMethodFetchAPI } from '../../api/Paymentpost/PaymentMethod'
import { DiscountFetchAPI } from '../../api/Discounts';
import { MachinesFetchAPI } from '../../api/Machines';
import { PositionFetchAPI } from '../../api/Position';
import { FilterOrderFetchAPI } from '../../api/Paymentpost/FilterOrder';
import { AuthDgiFetchAPI } from '../../api/Paymentpost/AuthDgi';
import { OrderNumberFetchAPI } from '../../api/Paymentpost/OrderNumber';
import { DivicesFechtAPI } from '../../api/Divices';
import { VendingFetchAPI } from '../../api/LocalRequest/Vending';
import { RenderIf } from '../../components/utils/RenderIf';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import { OrdersFetchAPI } from '../../api/Orders';
import { OrderReturnFetchAPI } from '../../api/Paymentpost/OrderReturn';
import { YappyFetchAPI } from '../../api/Paymentpost/Yappy';
import { WhatsappFetchAPI } from '../../api/Whatsapp';
import { VendingSnakkyFetchAPI } from '../../api/LocalRequest/VendingSnakky';
import VendingModalYappy from './VendingModalYappy';
import ModalYappyQr from './ModalYappyQr';
import oledComunication from './OledComunication';
import Swal from 'sweetalert2';

import checkImg from './img/check.gif'
import errorImg from './img/error.gif';
import loadingImg from './img/loading.gif'

function VendingMetPayments({ showModalPayment, setShowModalPayment, shoppingCart, invoiceTotal, invoiceTotalItbms, invoiceSubtotal, invoiceTotalDiscount, dataClient, setDataClient, setShoppingCart, setShowModalShopingCart, setEventHandlerShoppingCart, setEventHandlerMenu, setStatusScreen, statusComunicationVending, calculateInvoiceTotals, groups, taxesArrayAll, orderNumber, setOrderNumber, payments, setPayments, oledScreenPayments, setAtivityTimer }) {
  const [displayKeyboard, setDisplayKeyboard] = useState('')
  const [item, setItem] = useState({ name: "", price: "", img: "" })
  const [data, setData] = useState({ name: "No registrado", ruc: "0-000-00", dv: "00" });
  const [paymentApproval, setPaymentApproval] = useState({ status: 0, description: "Procesando pago" })
  // !Cmabiar el de dispense 
  const [showModalDispense, setShowModalDispense] = useState(false);
  const [showModalPaymentProcess, setShowModalPaymentProcess] = useState(false);
  const [dataParamsPaymentVoucher, setDataParamsPaymentVoucher] = useState({ authorizationNumber: "", referenceNumber: "", systemTraceNumber: "" })
  const [dataInfoPaymentSelect, setDataInfoPaymentSelect] = useState({})
  const [showModalDatos, setShowModalDatos] = useState(false);
  const [showModaloutService, setShowModaloutService] = useState(false)
  const [paymentSelect, setPaymentSelect] = useState("")
  const [showModalYappy, setShowModalYappy] = useState(false);
  const [dataNoteCredit, setDataNoteCredit] = useState(false);
  const [dataRutaQr, setDataRutaQr] = useState("");
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [itemReadyDispense, setItemReadyDispense] = useState([]);
  const [voucher_Order, setVoucher_Order] = useState({});
  const [order_code_register, setOrder_code_register] = useState("")
  const [transaction_code_register, setTransaction_code_register] = useState("")
  const [eventDispense, setEventDispense] = useState(0);
  const [title, setTitle] = useState("Ingrese el ruc");
  const [statusError, setStatusError] = useState(false);
  const [ready, setReady] = useState(false);
  const [selectPaymentYappy, setSelectPaymentYappy] = useState({})
  const [modalYappyQr, setModalYappyQr] = useState(false);
  const [dataYappy, setDataYappy] = useState({});
  const [dataHash, setDataHash] = useState("");
  const [statusRequestYappy, setStatusRequestYappy] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({ payment: "", payment_id: 0, payment_data: {} })
  
  let token = localStorage.getItem('x-access-token');
  let machine = JSON.parse(localStorage.getItem('machine_vending'))
  let branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'));
  let business = JSON.parse(localStorage.getItem('business_vending'));
  
  
  const [showImg, setShowImg] = useState(false);

  const handleCloseImg = () => setShowImg(false);
  const handleShowImg = () => setShowImg(true);

  //input // discount
  const inputRefLog = useRef(null);
  const inputRefLogYappy = useRef(null);
  const inputRefLogDispensed = useRef(null);
  const inputConfirmation = useRef(null);

  setTimeout(() => {
    if (inputRefLog.current != null) {
      inputRefLog.current.focus()
    }

  }, 1000);


  const handleChangeYappy = (txt) => {
    if (txt == '0') {
      setModalYappyQr(false)
      oledScreenPayments();
      inputRefLogYappy.current.value = ""

      inputRefLog.current.focus()
    } else {
      inputRefLogYappy.current.value = ""
    }
  }

  const handleChange = (txt) => {
    /*if (e.key === 'Enter') {
      let inputValue = e.target.value
      let arrayDiscount = inputValue.split("X");
      getDiscountByCode(arrayDiscount[1]);

      document.getElementById("inputCodeQrDiscount").value = ""

    }*/

    console.log(txt);
    console.log(payments);

    if (txt == '0') {
      setShowModalPayment(false);
      
      clearShoppingCart(true)
    } else {


      if (parseInt(txt) <= payments.length) {
        let inputNum = (txt - 1)

        if (machine.machine_subtype_code === "VGG") {
          oledComunication(0, '2,0')
          setTimeout(() => {
            oledComunication(0, '2,1,1,30,20,Cobrando...')
          }, 50);

          setTimeout(() => {
            oledComunication(0, '2,1,1,30,30,' + txt + '.' + payments[inputNum].payment)
          }, 100);

          setTimeout(() => {
            oledComunication(0, '2,1,0.5,0,55,Tx:$' + invoiceTotalItbms + ' Total:$' + invoiceTotal)
          }, 150);
        }

        getOrderNumber()
        selectprocessPayment(payments[inputNum])
      } else {

      }
    }
    clearInputPayment()
  }

  const clearInputPayment = (txt) => {
    if (txt == undefined) { inputRefLog.current.value = "" } else { inputRefLog.current.value = txt }
    inputRefLog.current.focus()
    console.log('Limpiar');
  }


  //get dicount by code and apply discount
  const getDiscountByCode = async (code) => {
    //console.log("Este es el codigo de descuento")
    //console.log(code)
    try {
      const response = await DiscountFetchAPI.getDiscountByCode(code, token);
      let discountRate = response.data.data.discount.discountRate
      console.log(discountRate)
      console.log(shoppingCart)

      for (let i = 0; i < shoppingCart.length; i++) {

        let discount = ((shoppingCart[i].price * discountRate) / 100)
        console.log(discount)

        shoppingCart[i].unit_discount = discount;
        shoppingCart[i].product_discount = discount;
        shoppingCart[i].descuento = discount;
        shoppingCart[i].discount = discount;

      }
      setShoppingCart(shoppingCart);
      calculateInvoiceTotals(shoppingCart)
      ErrorAlert("", "Descuento aplicado con exito", "success")

    } catch (err) {
      ErrorAlert("", "Descuento no encontrado", "error")
      console.log(err)

    }
  }





  //Function to get the payments method
  const getPaymentsMethod = async () => {
    try {
      const response = await PaymentMethodFetchAPI.getPaymentMethod(machine.id, token);
      setPayments(response.data.data)
    } catch (err) {
      console.log(err);
    }
  }

  //get data client authentication
  const authDgi = async () => {
    let message = "";
    let titleAlert = "";
    let icon = "";
    try {
      const response = await AuthDgiFetchAPI.authenticationRuc(token, data)

      let dataDgi = response.data
      if (dataDgi.dgi_response.gResProc) {
        if (dataDgi.dgi_response.gResProc.dCodRes == "0680") {
          data.dv = response.data.dgi_response.dDV;
          data.ruc = response.data.dgi_response.dRuc;
          data.name = response.data.dgi_response.dNomb;
          setData(data)
          setShowModalDatos(false)//Hiden modal data

          message = "Datos del clientes obtenidos por la DGI"
          titleAlert = "Datos obtenido correctamente"
          icon = "success"
          ErrorAlert(message, title, icon)

        } else {
          message = "Ingrese los datos manualmente."
          titleAlert = "Datos no encontrados"
          icon = "warning"
          ErrorAlert(message, title, icon)
        }
      }
    } catch (err) {
      ErrorAlert("", "Datos no encontrados en la DGI", "error")
      console.log(err);
    }
  }

  //function save data client 8-712-140
  const saveDataClient = async () => {
    if (data.ruc == "0-000-00") {
      console.log("hola");
      data.ruc = displayKeyboard;

      setDisplayKeyboard("")
      setTitle("Ingrese el nombre:")
      LoadingAlert()
      authDgi()
    } else if (data.name == "Cliente") {
      data.name = displayKeyboard;
      setDisplayKeyboard("")
      setTitle("Ingrese el dv:")
    } else if (data.dv == "00") {
      data.dv = displayKeyboard;
      setData(data)
      setShowModalDatos(false)//Hiden modal data
      setDisplayKeyboard("")
      setTitle("Ingrese el ruc:")
    }
  }

  //quiestion to change data client
  const handleDataClient = () => {
    if (data.ruc == "0-000-00") {
      setShowModalDatos(true)
    } else {
      Swal.fire({
        title: 'Quieres cambiar los datos?',
        text: "Al aceptar se borraran lo datos que ya tenias.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          setData({ name: "Cliente", ruc: "0-000-00", dv: "00" })
          setShowModalDatos(true)
        }
      })
    }
  }

  //notication info to the server local c#
  const infoMachineLocal = async () => {
    const JsonTr = [{
      Tipo: 'INFORMACION',
      Producto: machine.id,
      Monto: 0,
      TipoVenta: 'INFORMACION',
      printer_name: "",
      x_access_token: token,
      branchoffice_id: branchoffice.id
    }];
    try {
      const response = await RequestLocalFetchAPI.postPaymentMethod(JsonTr)
    } catch (err) {
      console.log(err);
    }
  }

  //check payment
  const paymentValidYappy = async (e, payment, phone) => {

    let EMVStreamResponseReponse = "0"
    setShowModalPaymentProcess(true)
    e.preventDefault();
    setDataInfoPaymentSelect(payment.payment)
    let type_Payment = payment.payment

    try {
      const lastOrder = await OrdersFetchAPI.getNumberOrder(branchoffice.id, token);
      let JsonTr = [{
        Tipo: 'SALE',
        Producto: 'COBRAR',
        Itbms: invoiceTotalItbms.toFixed(2),
        Descuento: invoiceTotalDiscount,
        Monto: invoiceTotal.toFixed(2),
        TipoVenta: type_Payment.toUpperCase(),
        paymentMethod: payment,
        orderid: (parseInt(lastOrder.data.last_transaction_code) + 1),
        taxes: invoiceTotalItbms.toFixed(2),
        subtotal: (invoiceTotal.toFixed(2) - invoiceTotalItbms.toFixed(2)),
        total: invoiceTotal,
        tel: "507" + phone,
      }];


      const response = await RequestLocalFetchAPI.postPaymentMethodVending(JsonTr);
      let responseCode = response.data.EMVStreamResponse.responseCode;
      let description = response.data.EMVStreamResponse.responseCodeDescription
      setVoucher_Order("0")

      if (responseCode == "20") {
        setStatusScreen(true);

        setPaymentApproval({ status: 1, description: description });

      } else {
        setPaymentApproval({ status: 2, description: description });
      }
      setTimeout(() => {
        if (responseCode == "20") {
          setShowModalDispense(true);
          setShowModalPayment(false)
          registerOrder(payment.payment_id, EMVStreamResponseReponse, payment);

        }
        setShowModalPaymentProcess(false)
        setPaymentApproval({ status: 0, description: "Procesando pago..." })
      }, 1500);
    } catch (err) {
      console.log(err);
    }

  }


  //check payment
  const paymentValid = async (payment) => {
    setPaymentSelect(payment.payment)
    let EMVStreamResponseReponse = "0"
    setShowModalPaymentProcess(true)

    setDataInfoPaymentSelect(payment.payment)
    if (payment.payment == "Credito") {
      setPaymentApproval({ status: 1, description: "APROBADO" });
      oledDrop()
      setVoucher_Order("0")
      setStatusScreen(true);
      //formatVoucherString("Terminald ID:                   WSP00065          *  ANULACION  *           SMART PAY - WSP         418794XXXXXX7313AUTH: 238912                    TRX: 608REF:                            13714333FECHA: 21/07/2023               11:02 AMTOTAL:                         USD. 0.01       FIRMA: _______                                                          ** FIN  **           - - - - - - - - - - - - - - - - - - - -        *  COPIA CLIENTE  *         Terminald ID:                   WSP00065          *  ANULACION  *           SMART PAY - WSP         418794XXXXXX7313AUTH: 238912                    TRX: 608REF:                            13714333FECHA: 21/07/2023               11:02 AMTOTAL:                         USD. 0.01           ** FIN  **           ")


      setTimeout(() => {
        setShowModalDispense(true);
        registerOrder(payment.payment_id, EMVStreamResponseReponse, payment);
        setShowModalPaymentProcess(false)
        setPaymentApproval({ status: 0, description: "Procesando pago..." })
      }, 1500);
    } else {

      console.log("ENTRE A SELECCIONAR");

      let type_Payment = ""
      if (payment.payment == "MasterCard") {
        type_Payment = "MC"
      } else {
        type_Payment = payment.payment
      }
      try {
        const lastOrder = await OrdersFetchAPI.getNumberOrder(branchoffice.id, token);
        let JsonTr = [{
          Tipo: 'SALE',
          Producto: 'COBRAR',
          Itbms: invoiceTotalItbms,
          Descuento: invoiceTotalDiscount,
          Monto: invoiceTotal.toFixed(2),
          TipoVenta: type_Payment.toUpperCase(),
          paymentMethod: payment,
          orderid: (parseInt(lastOrder.data.last_transaction_code) + 1),
        }];



        const response = await RequestLocalFetchAPI.postPaymentMethod(JsonTr);
        
        console.log(response);
        let responseCode = response.data.EMVStreamResponse.responseCode;
        let description = response.data.EMVStreamResponse.responseCodeDescription
        console.log(response.data.EMVStreamResponse.voucher);
        setVoucher_Order(response.data.EMVStreamResponse.voucher);



        if (type_Payment != "Yappy" && type_Payment != "Efectivo" && type_Payment != "Credito") {
          setDataParamsPaymentVoucher({ authorizationNumber: response.data.EMVStreamResponse.authorizationNumber, referenceNumber: response.data.EMVStreamResponse.referenceNumber, systemTraceNumber: response.data.EMVStreamResponse.systemTraceNumber })
          EMVStreamResponseReponse = response.data.EMVStreamResponse
        } else {
          setVoucher_Order("0")
        }


        if (responseCode == "00") {
          setStatusScreen(true);
          setPaymentApproval({ status: 1, description: description });
          oledDrop()
        } else {
          setPaymentApproval({ status: 2, description: description });
          olederror(description);
        }
        setTimeout(() => {
          if (responseCode == "00") {
            setShowModalDispense(true);
            registerOrder(payment.payment_id, EMVStreamResponseReponse, payment);
          }
          setShowModalPaymentProcess(false)
          setPaymentApproval({ status: 0, description: "Procesando pago..." })
        }, 1500);
      } catch (err) {
        setShowModalPaymentProcess(false)
        olederror()
        console.log(err);
      }
    }
  }

  // funtion convert format voucher
  const formatVoucherString = (voucher) => {
    // Extraer el número de tarjeta ocultando los dígitos intermedios
    const maskedVoucher = voucher.replace(/(\d{6})\d+(\d{4})/g, "$1XXXXXX$2");

    // Construir el string formateado del voucher
    const formattedVoucher = `${maskedVoucher}`;
    console.log(formattedVoucher);
    setVoucher_Order(formattedVoucher)
    //return formattedVoucher;
  };


  //Return amount order
  const voidCreditDebit = async () => {
    if (dataInfoPaymentSelect == "Credito") {
      registerOrderTempReturn({ EMVStreamResponse: "0" })
    } else {


      try {
        LoadingAlert("Procesando devolucion...")
        //request return total of the transaction

        console.log(paymentInfo);
        const json = [{ Tipo: "VOID", TipoVenta: "VOID", authorizationNumber: dataParamsPaymentVoucher.authorizationNumber, referenceNumber: dataParamsPaymentVoucher.referenceNumber, systemTraceNumber: dataParamsPaymentVoucher.systemTraceNumber, paymentMethod: { id: paymentInfo.payment_data.payment_id, payment: paymentInfo.payment_data.payment, payment_id: paymentInfo.payment_data.payment_id, machine_id: paymentInfo.payment_data.machine_id, params: paymentInfo.payment_data.params }}];
        console.log(json);
        const response = await RequestLocalFetchAPI.postPaymentMethod(json);
        console.log(response.data);
        let newVoucher = { EMVStreamResponse: response.data.EMVStreamResponse }
        setVoucher_Order(response.data.EMVStreamResponse.voucher);


        if (response.data.EMVStreamResponse.responseCodeDescription) {
          if (response.data.EMVStreamResponse.responseCodeDescription == "APROBADA") {
            registerOrderTempReturn(newVoucher)
            oledsuccessVoid()
          } else {
            ErrorAlert("No se aplico la devolucion.", "Error en la devolucion", "error")
            olederror("Error en la devolucion", false)
          }
        } else {
          ErrorAlert("No se aplico la devolucion.", "Error en la devolucion", "error")
          olederror("Error en la devolucion", false)
        }

      } catch (err) {
        console.log(err);
      }
    }
  }

  //register order return
  const registerOrderTempReturn = async (data_bank_voucher_response) => {

    let token = localStorage.getItem('x-access-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
    let business = JSON.parse(localStorage.getItem('business_vending'));

    try {
      const response = await OrderReturnFetchAPI.registerOrderReturn(transaction_code_register, business.code, branchoffice.code, token, data_bank_voucher_response);
      console.log(response);
      //getOrder(response.data.data.transaction_code)
      ErrorAlert("Devolucion exitosa.", "La devolucion se realizo correctamente ", "success")
      console.log(response.data.data.dgi_qr);

      if (response.data.data.dgi_qr == "") {
        setDataRutaQr("0")
      } else {
        setDataRutaQr(response.data.data.dgi_qr)
      }
      console.log(response.data.data.order_code);
      setOrder_code_register(response.data.data.order_code)
      setDataNoteCredit(true);


      {/*setTimeout(() => {
        clearShoppingCart()
      }, 120000);*/}


    } catch (err) {
      console.log(err);
    }
  }


  //selected payment
  const selectPayment = (e, payment) => {
    setAtivityTimer(2)
    e.preventDefault();
    selectprocessPayment(payment)
  
  }


  const selectprocessPayment = (payment) => {
    if (statusComunicationVending == true) {
      setPaymentInfo({ payment: payment.payment, payment_id: payment.payment_id, payment_data: payment })
      if (payment.payment == "Yappy") {
        handlerYappy(payment)
      } else if (payment.payment == "Yappy QR") {
        GenerateYappyTransaction(payment)
      } else {
        paymentValid(payment)
      }
    } else {
      setShowModaloutService(true)
    }
  }

  //Register order
  const registerOrder = async (payment_id, EMVStreamResponseReponse, payment) => {

    console.log(EMVStreamResponseReponse);
    console.log(payment);
    let token = localStorage.getItem('x-access-token')

    let json = {
      "EMVStreamResponse": EMVStreamResponseReponse,
      "client_name": data.name,
      "id_Client": 0,
      "ruc": data.ruc,
      "dv": data.dv,
      "localizador": 0,
      "client_phone": "0",
      "dgi_qr": "0",
      "Orden": shoppingCart,
      "Descuento": invoiceTotalDiscount,
      "Total": invoiceTotal,
      "itbms": invoiceTotalItbms,
      "taxes": taxesArrayAll,
      "subtotal": invoiceSubtotal,
      "machine_id": machine.id,
      "branchoffice_id": branchoffice.id,
      "tipoOrden": "comer aqui",
      "paymethod": payment_id,
      "paymenthod_integration_id": payment.integration.id
    }
    try {
      localStorage.setItem('countNotification', 0);
      const response = await OrderNumberFetchAPI.registerOrder(json, token)
      setOrder_code_register(response.data.data.order_code)
      setTransaction_code_register(response.data.data.transaction_code)
      shoppingCartDispense(payment.payment, response.data.data.order_code);
      getOrder(response.data.data.transaction_code);

       //type integration micros
       if (response.data.data.micros_params != null) {

        //params micros
        let micros_params = response.data.data.micros_params;
        let ip = micros_params.localMicrosServerIp


        //createCheckMicrosOrder(ip[0], micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token)
        createCheckMicrosOrderLocal(ip, micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token, response.data.data.order_id);
     } else {
        console.log("no hay parametros de micros");
     }
    } catch (err) {
      console.log(err);
      setShowModalDispense(false);
      ErrorAlert('', 'Error de registro', 'error');
    }
  }

  //We make the micros check 
  const createCheckMicrosOrderLocal = async (ip, RvcRef, employeeNum, host_url, orderData, token, order_id) => {
    let data = {
       Ruta: `http://${ip}:9472/api/micros/checks?RvcRef=${RvcRef}&employeeNum=${employeeNum}&host_url=${host_url}`,
       Type: "POST",
       Header: { "x-access-token": token },
       Body: orderData
    };
    let micros_response;
    console.log("entre a peticion local micros");

    try {
       micros_response = await RequestLocalFetchAPI.postMicros(data);
       console.log(micros_response);
       try {
          //We validate if the micros response is success
          if (micros_response.data.micros_response.PostTransactionExResponse.pTotalsResponse.OperationalResult.ErrorCode._text !== undefined && micros_response.data.micros_response.PostTransactionExResponse.pTotalsResponse.OperationalResult.ErrorCode._text === "Success") {
             console.log("vamos a actualizar")
             //We add the data that we want to update 
             let data_to_update = {
                micros_reference: {
                   checkNum: micros_response.data.micros_response.PostTransactionExResponse.pGuestCheck.CheckNum._text,
                   checkSeq: micros_response.data.micros_response.PostTransactionExResponse.pGuestCheck.CheckSeq._text,
                }
             }
             //We make the update of the order 
             const response = await OrdersFetchAPI.updateOrder(order_id, data_to_update, token);
             console.log(response)
          }
       } catch (err) {
          console.log(err)
       }
    } catch (err) {
       console.log(err);
    }
 }


  //----------------------------yappy qr------------------------------------------

  // get order number in process
  const getOrderNumber = async () => {
    //let branchoffice_vending =  JSON.parse(localStorage.getItem("branchoffice_vending"));
    try {
      const response = await OrderNumberFetchAPI.getOrderNumber(branchoffice.id, token)
      //console.log(response);
      setOrderNumber((parseInt(response.data.last_transaction_code) + 1));
    } catch (err) {
      console.log(err);
    }
  }


  //decimal reduction
  const decimalReduction = (value) => {

    let string = value.toString()
    let arrayString = string.split(".");
    let newDecimalString = []
    let sample = ''
    if (arrayString[1] != undefined) {
      newDecimalString = arrayString[1].slice(0, 2);
      sample = arrayString[0] + "." + newDecimalString
      console.log("Hice la reduccion");
    } else {
      console.log("Array: " + arrayString);
      console.log("Decimal: " + newDecimalString);
      console.log("Nuevo valor: " + sample);
      sample = string;
    }

    let endValue = parseFloat(sample)
    return endValue
  }

  //genrate yappy transaction
  const GenerateYappyTransaction = async (payment) => {
    //setModalYappyQr(true)
    setDataInfoPaymentSelect(payment.payment)
    LoadingAlert()

    let token = localStorage.getItem('x-access-token');
    let machine = JSON.parse(localStorage.getItem('machine_vending'));
    let dataOrder = JSON.parse(localStorage.getItem('Detail_order'))
    let data = {
      "machine_id": machine.id,
      "total": decimalReduction(invoiceTotal).toString(),
      "subtotal": decimalReduction(invoiceTotal).toString(),
      "tax": "0",
      "discount": "0",
      "transaction_code": orderNumber,
      "description": "Smartpay - orden:" + orderNumber,
      "params": payment.params
    }
    try {
      const response = await YappyFetchAPI.newYappyQr(data, token)
      console.log(response);


      ErrorAlert("", "Todo listo para que realices el pago", "info")
      setDataYappy(response.data);

      oledQrYappy(response.data.yappy_transaction.body.hash)
      setDataHash(response.data.yappy_transaction.body.hash)
      console.log(response.data.yappy_transaction.body.hash);
      setModalYappyQr(true)
      setStatusRequestYappy(true)
      //getYappyTransaction(response.data)


    } catch (err) {
      ErrorAlert("", "Lo sentimos yappy no esta disponible", "warning")
      olederror('  Yappy no disponible')
      console.log(err);
    }
  }

  const hadleCancelYappy = (text) => {

    if (text != undefined) {
      setPaymentApproval({ status: 2, description: text });
    } else {
      setPaymentApproval({ status: 2, description: "CANCELADO" });
    }

    setModalYappyQr(false);
    setStatusRequestYappy(false)
    console.log("cancele yappy");

    setTimeout(() => {
      setPaymentInfo({ payment: "", payment_id: 0 })

      setPaymentApproval({ status: 0, description: "Procesando pago..." })
    }, 3000);
  }

  const getYappyTransaction = async () => {
    console.log(dataYappy);
    let token = localStorage.getItem('x-access-token');

    try {
      let yappy_transaction_id = dataYappy.yappy_transaction.body.transactionId
      console.log(yappy_transaction_id);

      const response = await YappyFetchAPI.getYappyTransaction(yappy_transaction_id, token);
      console.log(response);

      let status = response.data.yappy_transaction.body.status;

      switch (status) {
        case 'PENDING':
          console.log("Transaccion pendiente");
          break;

        case 'COMPLETED':
          console.log("Transaccion aprobada");
          oledDrop()
          setVoucher_Order("0")
          setModalYappyQr(false)
          setStatusRequestYappy(false)
          setShowModalDispense(true);
          setShowModalPayment(false)
          setPaymentApproval({ status: 1, description: "APROBADO" })
          registerOrder(paymentInfo.payment_id, 0, paymentInfo.payment_data);

          break;

        case 'DECLINED':
          olederror('        DECLINED')
          setPaymentInfo({ payment: "", payment_id: 0 })
          console.log("Transaccion declinda");
          setDataHash("")
          hadleCancelYappy('DECLINED')

          break;

        case 'EXPIRED':
          olederror('        EXPIRED')
          setPaymentInfo({ payment: "", payment_id: 0 })
          console.log("Transaccion expirada");
          setDataHash("");
          hadleCancelYappy('EXPIRED')
          break;

        case 'FAILED':
          olederror('        FAILED')
          setPaymentInfo({ payment: "", payment_id: 0 })
          console.log("Transaccion fallida");
          setDataHash("")
          hadleCancelYappy('FAILED')
          break;

        case 'VOIDED':
          olederror('        VOIDED')
          setPaymentInfo({ payment: "", payment_id: 0 })
          console.log("Transaccion Eliminada");
          setDataHash("")
          hadleCancelYappy('VOIDED')
          break;

      }

    } catch (err) {
      console.log(err);
      setPaymentInfo({ payment: "", payment_id: 0 })
      setDataHash("")
      hadleCancelYappy("ERROR DE CONSULTA")
      olederror()

    }

  }
  //---------------================================================================

  //get order
  const getOrder = async (transaction_code) => {
    console.log("Estoy en consultar orden");
    try {
      const response = await FilterOrderFetchAPI.getOrder(transaction_code, branchoffice.code, business.code, token)

      console.log(response.data.EMVStreamResponse.voucher);
      setVoucher_Order(response.data.EMVStreamResponse.voucher)
      formatVoucherString(response.data.EMVStreamResponse.voucher)

    } catch (err) {
      console.log(err);
    }
  }

  //Function action yappy
  const handlerYappy = (payment) => {
    setPaymentSelect(payment.payment)
    setShowModalYappy(true)
    setSelectPaymentYappy(payment)
  }



  //Function to view the payments
  const viewPayment = () => {
    const listPayments = payments.map((payment) => (
      <>
        <div className="col-4 col-md-4 col-lg-4  d-flex justify-content-center p-1 " onClick={async (e) => (getOrderNumber(), selectPayment(e, payment))}>
          <input type="radio" className="btn-check"
            name="option_payments" id={payment.payment + "payment"}
          />
          <label className="btn btn-pay-meth " htmlFor={payment.payment + "payment"}>
            <img src={payment.img}
              className="img-pay-group  rounded border-vending-info "
              alt="Imagen Payments"
            />
            <h6 className='title-small-pay px-3 mt-1'>
              {payment.payment}
            </h6>
          </label>
        </div>
      </>
    ))
    return listPayments
  }

  //get divices of the machine
  const getdivise = async () => {
    try {
      const response = await DivicesFechtAPI.getDivices(machine.id, token)
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  const sendMessage = async () => {
    console.log("entre aqui");
    try {
      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
      console.log(response_machine);
      let data = {
        "notification_type": "smartpay_notification",
        "branchoffice_id": branchoffice.id,
        "business_id": business.id,
        "phone_to_send": branchoffice.branchoffice_support_phone,
        "header_text": "Error de despacho",
        "message_body": [{ "text": response_machine.data.data.machine_name + "de la empresa " + business.name + " no se despacho correctamente el producto en la posicion: " + "X:" + shoppingCart[0].X + ", Y:" + shoppingCart[0].Y + " de la orden: " + order_code_register + ", metodo de pago: " + paymentSelect }]
      }

      console.log("entre y envie mensaje");
      const response = await WhatsappFetchAPI.sendNotificationWhatsapp(data, token)
      console.log(response);
    } catch (err) {
      console.log("entre error en mensaje");
      console.log(err);
    }
  }

  const sendMessage2 = async (payment, order_code) => {
    machine = JSON.parse(localStorage.getItem('machine_vending'))
    branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
    business = JSON.parse(localStorage.getItem('business_vending'))


    try {
      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token);

      let data = {
        "notification_type": "smartpay_notification",
        "branchoffice_id": branchoffice.id,
        "business_id": business.id,
        "phone_to_send": branchoffice.branchoffice_support_phone,
        "header_text": "Error de comunicacion",
        "message_body": [{ "text": response_machine.data.data.machine_name + " de la empresa " + business.name + " no se despacho correctamente el producto en la posicion: " + "X:" + shoppingCart[0].X + ", Y:" + shoppingCart[0].Y }, { "text": "de la orden: " + order_code + ", metodo de pago: " + payment }]
      }
      console.log(data);
      const response = await WhatsappFetchAPI.sendNotificationWhatsapp(data, token)
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  //dispatch sequence
  let intervalNewOrder = 0
  const shoppingCartDispense = async (payment, order_code) => {
    console.log(payment + " " + order_code);

    let tempDispense = new Array;
    let statusValid = 0;
    //setShowModalDispense(true);
    for (let item = 0; item < shoppingCart.length; item++) {
      let res = 0;
      if (shoppingCart[item].amount > 1) {
        for (let i = 0; i < shoppingCart[item].amount; i++) {
          if (machine.machine_subtype_code == "VCG") {
            res = await dispense(shoppingCart[item].X, shoppingCart[item].Y);
          } else if (machine.machine_subtype_code == "VGG") {

            console.log("entre a snakky despacho");
            res = await dispenseSnakky(shoppingCart[item].X)
            setTimeout(async () => {
              await dispenseSnakky(shoppingCart[item].Y)
            }, 100)

            setTimeout(async () => {
              let statusDispence = await dispenseSnakkyStatus("5000")
              if (statusDispence == 200) {
                //status = 0
              }
            }, 10000)
          }
          tempDispense.push(shoppingCart[item]);
          updateStockItem(shoppingCart[item].group_id, shoppingCart[item].position_id, (shoppingCart[item].stock - 1))
          setItemReadyDispense(tempDispense);
          setEventDispense(1);
        }
        finishDispensed()
      } else {

        let status = 0
        let newItemDispense = shoppingCart[item]
        try {

          if (machine.machine_subtype_code == "VCG") {
            res = await dispense(shoppingCart[item].X, shoppingCart[item].Y);
            status = res.data.data

            updateStockItem(shoppingCart[item].group_id, shoppingCart[item].position_id, (shoppingCart[item].stock - shoppingCart[item].amount))
            newItemDispense.status = "success"
            setItemReadyDispense([...itemReadyDispense, newItemDispense]);
            tempDispense.push(shoppingCart[item]);
            setItemReadyDispense(tempDispense);
            setEventDispense(1);
            finishDispensed()
          } else if (machine.machine_subtype_code == "VGG") {
            //res = await dispenseSnakky('1')
            await dispenseSnakky(shoppingCart[item].X)

            /* setTimeout(async () => {
               await dispenseSnakky(shoppingCart[item].X)
 
             }, 100)*/

            setTimeout(async () => {
              await dispenseSnakky(shoppingCart[item].Y)
              status = 0
            }, 300)

            setTimeout(async () => {
              let datasensore = [{ "comandaVendType": 0, "vendingCode": "4,0,10000" }]
              await VendingSnakkyFetchAPI.keypad(datasensore)
              requestActiveSensorDrop()
            }, 400)

            let turnDrop = 0
            const requestActiveSensorDrop = async () => {
              setTimeout(async () => {
                let statusDispence = await dispenseSnakkyStatus("10000")
                proccessrResponse(statusDispence)
              }, 1000)
            }

            //Function proccess data for drop
            function proccessrResponse(statusDispence) {
              if (statusDispence.data.Informacion == "400") {
                status = 0
                console.log("entre a success 1" + statusDispence.data.Informacion);

                oledsuccess()
                updateStockItem(shoppingCart[item].group_id, shoppingCart[item].position_id, (shoppingCart[item].stock - shoppingCart[item].amount))
                newItemDispense.status = "success"
                setItemReadyDispense([...itemReadyDispense, newItemDispense]);
                tempDispense.push(shoppingCart[item]);
                setItemReadyDispense(tempDispense);
                setEventDispense(1);
                finishDispensed()
              } else {
                if (turnDrop < 15) {
                  console.log("error de despacho # " + turnDrop);
                  turnDrop++
                  requestActiveSensorDrop()
                } else {

                  status = 1
                  console.log("No fue posible realizar el despacho");
                  sendMessage2(payment, order_code);
                  updateStockItem(shoppingCart[item].group_id, shoppingCart[item].position_id, 0)
                  newItemDispense.status = "error"
                  setItemReadyDispense([...itemReadyDispense, newItemDispense]);
                  tempDispense.push(newItemDispense);
                  setItemReadyDispense(tempDispense);
                  setEventDispense(1);
                  setStatusError(true)


                  olederror("Error de despacho", true)
                  statusValid = 1
                  clearStatussentorandlimitDigit(1, "2,1,4,40,20,")

                  setTimeout(() => {
                    inputRefLogDispensed.current.focus()
                  }, 2000);



                }
              }
            }
          }

        } catch (err) {
          status = 1

          console.log("entre a error 2");
          sendMessage2(payment, order_code);
          updateStockItem(shoppingCart[item].group_id, shoppingCart[item].position_id, 0)
          newItemDispense.status = "error"
          setItemReadyDispense([...itemReadyDispense, newItemDispense]);
          tempDispense.push(newItemDispense);
          setItemReadyDispense(tempDispense);
          setEventDispense(1);
          setStatusError(true)

          statusValid = 1

          if (machine.machine_subtype_code == "VGG") {
            setTimeout(() => {
              oledResponse('ERROR', '  Error de despacho');
            }, 500);
          }
        }

      }
    }
    if (statusValid == 1) {
      setStatusError(true)
    }

    function finishDispensed() {
      {/*setTimeout(() => {
        setReady(true);
      }, 3000);*/}
      setReady(true);
      setAtivityTimer(7)
      if (machine.machine_subtype_code === "VGG") {

        setShowModalConfirmation(true);

        setTimeout(() =>{
          inputConfirmation.current.focus()
          oledFinishConfirmation()
        }, 1500)
        
        /*intervalNewOrder = setInterval(() => {
          clearShoppingCart()
        }, 30000);*/
        /*setTimeout(() => {
          clearShoppingCart()
        }, 3000);*/
      } else {
        setShowModalConfirmation(true);
        intervalNewOrder = setInterval(() => {
          clearShoppingCart()
        }, 30000);

        {/*setTimeout(() => {
          clearShoppingCart();
        }, 5000);*/}
      }
    }
  }

  //================OLED SCREEN=======================

  const oledResponse = (status, description) => {
    oledComunication(0, '2,0')
    setTimeout(() => {
      oledComunication(0, '2,1,2,35,20,' + status)
    }, 50);

    setTimeout(() => {
      oledComunication(0, '2,1,1,0,40,' + description)
    }, 100);
  }

  const oledsuccess = () => {
   oledComunication(0, '5,0,2,0'); //color led exterior vending
    if (machine.machine_subtype_code === 'VGG') {
      setTimeout(() => {
        oledResponse('EXITO', '  Retire su producto');
      }, 800);
    }
  }

  const oledsuccessVoid = () => {
    oledComunication(0, '5,0,2,0'); //color led exterior vending
    if (machine.machine_subtype_code === 'VGG') {
      setTimeout(() => {
        oledResponse('EXITO', '  Devolucion realizada con exito');
      }, 500);
    }
  }

  const oledSupport = () => {
    oledComunication(0, '5,0,7,0') //color led exterior vending
    setAtivityTimer(5)
    if (machine.machine_subtype_code === 'VGG') {
      //setTimeout(() => {
      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0, '2,1,1,15,30,ERROR DE DESPACHO')
      }, 50);

      setTimeout(() => {
        oledComunication(0, "2,1,1,15,45,Preciona un boton   para ser asistido")
      }, 100);
      //}, 500);
    }
  }

  const oledFinishConfirmation = () => {
    
    if (machine.machine_subtype_code === 'VGG') {
      //setTimeout(() => {
      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0, "2,1,1,10,10,Lee el QR para ver")
      }, 50);

      setTimeout(() => {
        oledComunication(0, "2,1,1,35,20,tu factura")
      }, 100);

      setTimeout(() => {
        oledComunication(0, "2,1,1,25,40,0.Nueva orden")
      }, 150);

      if(voucher_Order != "0"){
        setTimeout(() => {
          oledComunication(0, "2,1,1,25,50,1.Ver voucher")
        }, 200);
      }
     
      //}, 500);
    }
  }

  const oledDrop = () => {
    if (machine.machine_subtype_code === 'VGG') {
      oledComunication(0, '5,1,3,0,0,3,200,10000,0') 

      setTimeout(() => {
        oledComunication(0, '2,0')
        setTimeout(() => {
          oledComunication(0, '2,1,1,30,30,' + "DESPACHANDO")
        }, 50);

        setTimeout(() => {
          oledComunication(0, '2,1,1,15,40,' + "Espere un momento")
        }, 100);
      }, 500);
    }
  }

  const olederror = (txt, type) => {
    oledComunication(0, '5,1,1,0,0,3,200,10000,0') 
    setTimeout(() => {oledComunication(0, '5,0,1,0')}, 200);  //color led exterior vending
    setAtivityTimer(5)
    if (machine.machine_subtype_code === 'VGG') {
      let description = '    Error de pago'
      if (txt != undefined) {
        description = txt
      }

      if (type == true) {
        oledSupport()
      } else {
        setTimeout(() => {
          oledResponse('ERROR', description)

          setTimeout(() => {
            oledScreenPayments()
          }, 1000);
        }, 500);
      }

    }
  }


  const oledQrYappy = (hash) => {

    if (machine.machine_subtype_code === 'VGG') {
      let jsonYappy = [{ "comandaVendType": 3, "vendingCode": "2,3," + hash + ", Yappy QR,  $" + parseFloat(invoiceTotal).toFixed(2) + ", , ,0.CANCELAR" }]


      oledComunication(0, '2,0')
      setTimeout(() => {
        console.log("tengo el hash:" + hash);
        console.log(jsonYappy)
        oledComunication(3, '', jsonYappy)
      }, 50);

      setTimeout(() => {
        inputRefLogYappy.current.focus()
      }, 2000);

    }
  }
  //==================================================

  const refresh = () => {

    if (machine.machine_subtype_code === 'VGG') {
      oledComunication(0, '2,0')

      setTimeout(() => {
        oledComunication(0, '2,1,1,35,30,Preparando')
      }, 50);

      setTimeout(() => {
        oledComunication(0, '2,1,1,40,40,maquina...')
      }, 100);


      setTimeout(() => {
        window.location.reload(true);
      }, 150);

    } else {
      window.location.reload(true);
    }
  }

  //Function to clear the shopping cart
  const clearShoppingCart = (turnRefresh) => {

    if(turnRefresh == true){
      refresh()
    }
    clearInterval(intervalNewOrder)
    setItemReadyDispense([]);
    setShowModalDispense(false);
    setReady(false);
    setStatusError(false);
    setShowModalConfirmation(false)
    setDataNoteCredit(false)


    //Clear params voucher 
    setVoucher_Order("0")

    //Clear and reload all request
    //setEventHandlerMenu(1)

    //We set the shopping cart to empty
    setShoppingCart([]);
    //We close the modal
    setShowModalPayment(false)

    //We set the handler to recharge the data
    setEventHandlerShoppingCart(1);
    //We close the modal
    setShowModalPayment(false);

    setStatusScreen(false);


  };

  //action dispese in the vending machine
  const dispense = async (X, Y) => {
    return new Promise(async (resolve, reject) => {
      let json = { funcion: "DESPACHAR", datos: [{ X: X.toString(), Y: Y.toString() }] }
      try {
        const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
        const response = await VendingFetchAPI.operation(response_machine.data.data.android_ip, json)

        resolve(response);
        return (response);
      } catch (err) {
        reject(err);
        console.log(err);
      }
    });
  }


  //action dispese in the vending machine
  const dispenseSnakky = async (key) => {
    return new Promise(async (resolve, reject) => {
      try {

        let data = [{ "comandaVendType": 0, "vendingCode": "1," + key }]
        const response = await VendingSnakkyFetchAPI.keypad(data)

        resolve(response);
        return (response);
      } catch (err) {
        reject(err);
        console.log(err);
      }
    });
  }

  //state dispence in machine
  const dispenseSnakkyStatus = async (time) => {
    return new Promise(async (resolve, reject) => {
      try {
        let data = [{ "comandaVendType": 0, "vendingCode": "4,0," + time }]
        const responsedata = await VendingSnakkyFetchAPI.getDrop(data)
        resolve(responsedata);
        return (responsedata);

      } catch (err) {
        reject(err);
        console.log(err);
      }
    });


  }


  //update and stock product in the position
  const updateStockItem = async (group_id, position_id, amount) => {
    console.log("Actualizar la cantidad del producto");
    let item = {
      "group_id": group_id,
      "position_id": position_id,
      "amount": amount
    }

    try {
      const response = await PositionFetchAPI.updatePositionToGroup(machine.id, item, token)
      console.log(response);

      let dataNowGroupsAndPositions = []

      if (localStorage.getItem('GroupsByMachine') != undefined || localStorage.getItem('GroupsByMachine') != '') {
        dataNowGroupsAndPositions = JSON.parse(localStorage.getItem('GroupsByMachine'))
        console.log("intente actualizar el item");


        dataNowGroupsAndPositions.forEach(position => {
          shoppingCart.forEach(group => {
            if (group.position_id === position.position_id) {
              position.amount = amount
            }
          })
        });


        localStorage.setItem("GroupsByMachine", JSON.stringify(dataNowGroupsAndPositions))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const clearStatussentorandlimitDigit = async (data, settingtxt) => {
    try {
      await VendingSnakkyFetchAPI.clearData({ "limit": data, "setting": settingtxt })

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    //infoMachineLocal();
    getPaymentsMethod();
    getdivise();
  }, []);

  useEffect(() => {
    console.log(shoppingCart);
    console.log(invoiceTotal);
    if (shoppingCart.length > 0) {
      setItem({ name: shoppingCart[0].name, price: shoppingCart[0].price, img: shoppingCart[0].img })
    }
  }, [shoppingCart]);

  useEffect(() => {

    setEventDispense(0)
  }, [eventDispense]);

  useEffect(() => {
    let timer = 0
    if (statusRequestYappy == true) {
      timer = setInterval(async () => getYappyTransaction(), 5000); //INTERVAL GET YAPPY STATUS TRANSACTION

      return () => clearInterval(timer)
    } else {
      return () => clearInterval(timer) //CLEAR INTERVAL
    }

  }, [statusRequestYappy]);

  return (
    <>
      {/* showModalPayment */}
      <Modal
        show={showModalPayment}
        fullscreen
        onHide={() => setShowModalPayment(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body className='p-0 m-0' onClick={() => (inputRefLog.current.focus())}>
          <main className='no-scroll '  onClick={() => (inputRefLog.current.focus())}>
            {/* <div className='bd-highlight  w-100'>
              <h2 className='title-modal-cart text-center'>
                <i className="uil uil-transaction  icon-button"></i>
                Pago
              </h2>
            </div> */}
            {/* Card Master  */}
            <section className='position-relative border-vending-secondary mx-2 pb-3 pt-2 mt-3 mt-lg-5'>
              {/* <h4 className='title-modal-pay text-muted'>Producto a pagar</h4> */}
              <div className="d-flex justify-content-center ">
                <div className="d-flex align-items-center ">
                  <div className="flex-shrink-0 ms-2">
                    <img
                      // src='https://images.pexels.com/photos/1199957/pexels-photo-1199957.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                       src={item.img}
                      alt="..."
                      className='img-pay-meth border-vending-secondary'
                      onClick={handleShowImg}
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h2 className=' fw-bold text-product-pay text-dark'>
                    
                        {item.name} 


                    </h2>
                  </div>
                </div>


              </div>
              <section className='border-vending-success p-lg-4 p-2 mx-3 mt-2 '>
                <div className="d-flex  justify-content-between  align-items-center px-2 text-title-subtotals">
                  <small className="text-muted fw-bold"> Descuento: </small>
                  <span className=" text-secondary  ">
                    <span className="px-1 ">$</span>
                    {invoiceTotalDiscount.toFixed(2)}
                  </span>
                </div>


                <div>
                  {taxesArrayAll.map((tax) => (
                    <>
                      {tax.total > 0 ?
                        <div className="d-flex justify-content-between  align-items-center px-2 text-title-subtotals">
                          <small className=" text-muted  fw-bold"> Impuesto {tax.tax}:</small>
                          <span className=" text-secondary ">
                            <span className="px-1 ">$
                            </span>{tax.total.toFixed(2)}
                          </span>
                        </div>
                        : <></>}
                    </>
                  ))}
                </div>



                <div className="d-flex justify-content-between  align-items-center  px-2 text-title-subtotals">
                  <small className="text-muted fw-bold"> Subtotal: </small>
                  <span className="text-secondary ">
                    <span className="px-1 ">$
                    </span>{parseFloat(item.price).toFixed(2)}
                  </span>
                </div>

                <div className="d-flex justify-content-between text-title-totals d-block ">
                  <strong className="text-black fw-bold ">
                    Total:
                  </strong>
                  <span className="text-black fw-bold ">
                    <span className="px-2 ">$
                    </span>{invoiceTotal.toFixed(2)}
                  </span>
                </div>
              </section>

              <div className='position-absolute top-0 start-50 translate-middle'>
                <span className=' border-vending-secondary bg-white text-dark px-2 fw-bold text-subtitle-box'>Producto a pagar</span>
              </div>
            </section>



            <div className="mt-lg-5 mt-0" >
              <section className=' w-100'>
                {/* <div className='bd-highlight mb-auto met-pay w-100 mt-5 bg-light'>
                <h3 className='text-center title-modal-pay text-muted'>Datos del Cliente:</h3>
                <div className="d-flex alight-item-center justify-content-center  ">
                  <div>
                    <h3 className='text-center title-modal-cart'>Cliente:{data.name}</h3>
                    <p className='text-center title-modal-cart'>Ruc: {data.ruc} DV: {data.dv}</p>
                    <div className='d-flex align-item-center justify-content-end mt-5'>
                      <button className='link-primary display-5 fw-bold' onClick={() => (handleDataClient())}>
                        <i className="uil uil-edit-alt"></i> Editar datos
                      </button>
                    </div>
                  </div>
                </div>
              </div>   */}
              </section>
              <div className="p-0">              
                <div className='mt-4 position-relative pt-3 border-vending-secondary mx-2'>
                  <div className='row text-center mx-2 p-lg-3'>
                    {viewPayment()}
                  </div>
                  
                  <div className='position-absolute top-0 start-50 translate-middle'>
                    <span className=' border-vending-secondary bg-white text-dark px-2 fw-bold text-subtitle-box'>Métodos de pago</span>
                  </div>
                </div>
              </div>
              {/*Input discunt*/}
              <input id="inputCodeQrDiscount" className='vending-input-qr' ref={inputRefLog} inputMode="none" type="text" onChange={(e) => handleChange(e.target.value)} autoFocus></input>
            </div>
             <div className='position-absolute start-0 bottom-0' onClick={() => (inputRefLog.current.focus())}>
              <button className=' btn-vending-product px-3 py-2 ms-1 mb-1 '
                onClick={() => (setShowModalPayment(false), clearShoppingCart(true))}>
                <span className='text-msg-venidng fw-bold text-center'>
                  <i className="uil uil-arrow-left icon-button"></i>
                </span>
              </button>
            </div> 
          </main>
        </Modal.Body>
      </Modal >

      {/*Process payment modal  agregar en show{} showModalPaymentProcess */}
      <Modal
        show={showModalPaymentProcess}
        fullscreen
        onHide={() => setShowModalPaymentProcess(false)}
      >
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center h-100">
            <section className='d-flex align-items-start flex-column bd-highlight'>
              <div className='bd-highlight  w-100 '>
                <h2 className='title-modal-cart text-center'>
                  <i className="uil uil-transaction  icon-button"></i>
                  Pago
                </h2>
              </div>
              <div className=' bd-highlight  w-100 mt-4 mt-lg-4'>
                <div className="d-flex alight-item-center justify-content-center mt-1 mt-lg-4">
                  <div className='border-vending-success p-2'>
                    <h3 className=' text-msg-venidng '>Saldo a pagar</h3>
                    <h2 className='text-center text-msg-venidng fw-bold'>${invoiceTotal.toFixed(2)}</h2>
                  </div>
                </div>
                <RenderIf isTrue={dataInfoPaymentSelect == "Yappy"}>
                  <div className=''>
                    <h3 className=' text-center text-msg-venidng fw-bold m-5'>Revisa en PENDIENTES de yappy.</h3>
                  </div>
                </RenderIf>
              </div>
              <div className='bd-highlight met-pay w-100 mt-5'>
                <div className=" d-flex alight-item-center justify-content-center  mt-5">
                  <div>
                    <div className='mt-2'>
                      <div className='row'>
                        <RenderIf isTrue={paymentApproval.status == 1}>
                          <div className=" d-flex alight-item-center justify-content-center">
                            <img src={checkImg} className='img-ans' alt="Imagen Payments" ></img>
                          </div>
                        </RenderIf>
                        <RenderIf isTrue={paymentApproval.status == 2}>
                          <div className=" d-flex alight-item-center justify-content-center">
                            <img src={errorImg} className='img-ans' alt="Imagen Payments" ></img>
                          </div>
                        </RenderIf>
                        <RenderIf isTrue={paymentApproval.status == 0}>
                          <div className=" d-flex alight-item-center justify-content-center">
                            <img src={loadingImg} className='img-ans' alt="Imagen Payments" ></img>
                          </div>
                        </RenderIf>
                        <h3 className='text-center text-msg-venidng fw-bold'>{paymentApproval.description}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

        </Modal.Body>
      </Modal >

      <Modal
        show={showModalDatos}
        fullscreen
        onHide={() => setShowModalDatos(false)}
        aria-labelledby="example-custom-modal-styling-title">

        <Modal.Body>
          <div className='bd-highlight'>
            <h2 className='title-modal-cart text-center'>{title}</h2>
          </div>

          <Keyboard displayKeyboard={displayKeyboard} setDisplayKeyboard={setDisplayKeyboard} />

          <div className='bd-highlight w-100 row'>
            <div className='col-md-6'><button type="button" className=" btn btn-lg btn-vending w-100 m-2" onClick={() => (setShowModalDatos(false))}>
              Volver
            </button></div>
            <div className='col-md-6'><button type="button" className=" btn btn-lg btn-vending w-100 m-2" onClick={() => (saveDataClient())} >
              siguiente
            </button></div>
          </div>
        </Modal.Body>
      </Modal>

      <Vendingoutservice showModaloutService={showModaloutService} setShowModaloutService={setShowModaloutService} setEventHandlerMenu={setEventHandlerMenu} clearShoppingCart={clearShoppingCart} status={1} setAtivityTimer={setAtivityTimer}></Vendingoutservice>
      <VendingDispensed itemReadyDispense={itemReadyDispense} showModalDispense={showModalDispense} setShowModalDispense={setShowModalDispense} ready={ready} statusError={statusError} setStatusError={setStatusError} voucher_Order={voucher_Order} dataInfoPaymentSelect={dataInfoPaymentSelect} setEventHandlerMenu={setEventHandlerMenu} order_code_register={order_code_register} clearShoppingCart={clearShoppingCart} voidCreditDebit={voidCreditDebit} dataNoteCredit={dataNoteCredit} setDataRutaQr={setDataRutaQr} dataRutaQr={dataRutaQr} shoppingCart={shoppingCart} registerOrderTempReturn={registerOrderTempReturn} inputRefLogDispensed={inputRefLogDispensed} oledSupport={oledSupport} setAtivityTimer={setAtivityTimer}></VendingDispensed>
      <VendingModalConfirmation clearShoppingCart={clearShoppingCart} orderCode={order_code_register} total={invoiceTotal} orderNumber={transaction_code_register} showModalConfirmation={showModalConfirmation} setShowModalConfirmation={setShowModalConfirmation} voucher_Order={voucher_Order} dataInfoPaymentSelect={dataInfoPaymentSelect} setAtivityTimer={setAtivityTimer} refresh={refresh} oledFinishConfirmation={oledFinishConfirmation} inputConfirmation={inputConfirmation}></VendingModalConfirmation>
      <VendingModalYappy showModalYappy={showModalYappy} setShowModalYappy={setShowModalYappy} selectPaymentYappy={selectPaymentYappy} paymentValidYappy={paymentValidYappy}></VendingModalYappy>
      <ModalYappyQr paymentInfo={paymentInfo} modalYappyQr={modalYappyQr} setModalYappyQr={setModalYappyQr} dataYappy={dataYappy} setDataHash={setDataHash} dataHash={dataHash} hadleCancelYappy={hadleCancelYappy} invoiceTotal={invoiceTotal} orderNumber={orderNumber} inputRefLogYappy={inputRefLogYappy} handleChangeYappy={handleChangeYappy} oledScreenPayments={oledScreenPayments} ></ModalYappyQr>


{/* Modal de imagen de prodcto  */}
      <Modal show={showImg} onHide={handleCloseImg} centered>
        <Modal.Header closeButton>
          <Modal.Title>{item.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <img
                       src={item.img}
                      // src='https://images.pexels.com/photos/1199957/pexels-photo-1199957.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                      alt="..."
                      className='img-pay-meth-modal border-vending-secondary'
                      onClick={handleShowImg}
                    />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default VendingMetPayments