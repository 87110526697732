import React, { useState, useEffect, useRef } from 'react'
import qr_img from '../../assets/img/vending/qr.png'
import VendingModalKeyCode from '../Vending/VendingModalKeyCode'
import VendingStock from './VendingStock';
import { Modal } from 'react-bootstrap';
import scanQR from './img/scanqr.jpg'
import scanQR2 from './img/scanyourqr.gif'

import ErrorAlert from '../../components/Alerts/ErrorAlert';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';

//api
import { AuthFetchAPI } from '../../api/Auth';

function VendingModalOperator({ logo, showModalAuthOperator, setShowModalAuthOperator, humidity, temperature, setShowModalStock, showModalStock, setEventHandlerMenu, showModalKeyCode, setShowModalKeyCode, setStatusScreen, nameBusiness, setAtivityTimer }) {
    const [codeSesion, setCodeSesion] = useState({
        business_code: "0000",
        branchoffice_code: "0000",
        machine_code: "0000",
    });
    const inputRef = useRef(null);


    const authOperator = () => {

        let business = JSON.parse(localStorage.getItem('business_vending'));
        let branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'));
        let machine = JSON.parse(localStorage.getItem('machine_vending'));

        if (business.code == codeSesion.business_code) {
            if (branchoffice.code == codeSesion.branchoffice_code) {
                if (machine.code == codeSesion.machine_code) {
                    setStatusScreen(true);
                    ErrorAlert("Operador autenticado", "Acceso permitido", "success");
                    setShowModalAuthOperator(false);
                    setEventHandlerMenu(1);
                    setShowModalStock(true);

                } else {
                    ErrorAlert("Acceso denegado", "Codigo de maquina incorrecto", "error");
                    setShowModalAuthOperator(false);
                    document.getElementById("inputCodeQrOperator").value = ""

                }
            } else {
                ErrorAlert("Acceso denegado", "Codigo de sucursal incorrecto", "error");
                setShowModalAuthOperator(false);
                document.getElementById("inputCodeQrOperator").value = ""
            }
        } else {
            ErrorAlert("Acceso denegado", "Codigo de empresa incorrecto", "error");
            setShowModalAuthOperator(false);
            document.getElementById("inputCodeQrOperator").value = ""
        }
        setShowModalKeyCode(false)
        setCodeSesion({ business_code: "0000", branchoffice_code: "0000", machine_code: "0000", });

    }


    const handleChange = (e) => {
        if (e.key == "Enter") {
            LoadingAlert()
            let inputValue = e.target.value.toUpperCase();
            let arrayCodeString = inputValue.split("X");
            console.log(arrayCodeString);

            codeSesion.business_code = parseInt(arrayCodeString[3])
            codeSesion.branchoffice_code = parseInt(arrayCodeString[2])
            codeSesion.machine_code = parseInt(arrayCodeString[1])
            setCodeSesion(codeSesion)
            authOperator()
        }

    }

    function actualizar() {
        window.location.reload();
    }

    return (
        <>
            <div
                //onClick={() => (setShowModalStock(true))} 
                onClick={() => (setShowModalAuthOperator(true), setAtivityTimer(2), setTimeout(() => { inputRef.current.focus() }, 3000))}>
                {/* <img src={logo} className='logo-main-vending m-1' /> */}

                <h2 className='fw-bold fs-1 text-dark'>{nameBusiness}</h2>
            </div>


            <Modal
                show={showModalAuthOperator}
                size="lg"
                centered
                onHide={() => setShowModalAuthOperator(false)}
            >
                <Modal.Body>               
                    <main className='vending' onClick={() => (inputRef.current.focus())} >
                        <span className='text-bg-dark fw-bold px-2 rounded-3' onClick={() => actualizar()}>Abastecimiento</span>
                        <div className="d-flex align-items-center flex-column bd-highlight">
                            <div className='vendindg-title  bd-highlight  pt-1'>
                                <h2 className='fw-bold mb-0 text-center text-title-help'>
                                    Validación de Usuario
                                </h2>
                            </div>
                            <div className='container  mt-2'>
                                <div className='vendindg-message  border-vending-primary  p-2 rounded-3'>
                                    <span className="mb-0  text-title-help text-primary">
                                        <i className="uil uil-info-circle"></i>  Por favor, coloque el código QR en el escáner de la máquina.
                                    </span>
                                </div>

                                <h2 className="fw-bold my-2 text-center button-sub-title ">O </h2>
                                <div className='bd-highlight d-block'>
                                    <div className="d-flex justify-content-center">
                                        <div className='vendindg-message border-vending-success   p-2 rounded-3'>
                                            <span className="mb-0  text-title-help text-success" >
                                                <i className="uil uil-info-circle me-1"></i>
                                                Ingresar de forma manual los códigos en la máquina.
                                            </span>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <button className='border-vending-success  px-5 display-5 fw-bold pulse'onClick={() => (setShowModalKeyCode(true))} >Ingresar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <input id="inputCodeQrOperator" ref={inputRef} type='text' inputMode="none" onKeyPress={(e) => (handleChange(e))} className='vending-input-qr' autoFocus></input>


                </Modal.Body>

                <VendingModalKeyCode codeSesion={codeSesion} setCodeSesion={setCodeSesion} authMachineVending={authOperator} showModalKeyCode={showModalKeyCode} setShowModalKeyCode={setShowModalKeyCode}  ></VendingModalKeyCode>

                <Modal.Footer className='d-flex justify-content-start'>
                    <button className='btn-return-vending  m-2 '

                        onClick={() => (setShowModalAuthOperator(false),  setAtivityTimer(3))}
                    >
                        <i className="uil uil-arrow-left icon-button"></i>
                       
                    </button>
                </Modal.Footer>
            </Modal>



        </>
    )
}

export default VendingModalOperator