import React from 'react'
import Hero from './Hero'
import PagueloFacil from './PagueloFacil/PagueloFacil'
function Index() {
  return (
    <>    
        <Hero></Hero>  
        <PagueloFacil></PagueloFacil>     
    </>
  )
}

export default Index