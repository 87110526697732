import React, { useState, useRef, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { RenderIf } from '../../components/utils/RenderIf';
import QRCode from "react-qr-code";
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import imgCheck from "./img/check.gif"
import VendingModalVoucher from './VendingModalVoucher';
import { useWindowSize } from '../../hooks/useWindowSize';

function VendingModalConfirmation({ clearShoppingCart, orderCode, orderNumber, total, showModalConfirmation, setShowModalConfirmation, intervalNewOrder, voucher_Order, dataInfoPaymentSelect, setAtivityTimer, refresh, oledFinishConfirmation, inputConfirmation }) {

    const [showModalvoucher, setshowModalvoucher] = useState(false);
    let routeQr = ""
    let branchoffice = ""
    if (localStorage.getItem('branchoffice_vending')) {
        if (localStorage.getItem('branchoffice_vending') != null || localStorage.getItem('branchoffice_vending') != '') {
            branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
            //routeQr = "192.168.1.5:3000/order/" + orderCode + "/branchoffice/" + branchoffice.id
            routeQr = "https://smartpay.ai/order/" + orderCode + "/branchoffice/" + branchoffice.id
        }
    }

    const newOrder = () => {
        let message = "Gracias por su compra."
        let title = "Transaccion exitosa"
        let icon = 'success'

        clearShoppingCart(true)
        ErrorAlert(message, title, icon)
    }

    const handlercahge = (txt) => {

        if (txt == "1") {
            if (voucher_Order != "0") {
                setAtivityTimer(7)
                setshowModalvoucher(true)
            } else {
                inputConfirmation.current.value = ""
                newOrder()
            }
        } else {
            inputConfirmation.current.value = ""
            newOrder()
        }

    }

    useEffect(() => {
        if (showModalConfirmation == true) {

            const intervalConfirmation = setInterval(() => {
                inputConfirmation.current.focus()

            }, 2000);

            return () => clearInterval(intervalConfirmation);
        }


    }, [showModalConfirmation])
    //console.log(routeQr);

    const { width } = useWindowSize();
    const qrSize = width <= 480 ? "200" : "300";

    return (
        <>
            {/* showModalConfirmation */}
            <Modal
                show={showModalConfirmation}
                fullscreen
                onHide={() => setShowModalConfirmation(true)}
                backdrop="static"
                centered
                className='bg-blur-sp'
            >

                <Modal.Body>

                    <div onClick={() => setAtivityTimer(7)}>

                        <div className="">
                            {/* <img className='m-2' src={imgCheck} width="60px"></img> */}
                            <div className='my-4 py-2 border-vending-primary text-center'>
                                <h2 className=' fw-bold text-msg-footer '>Orden:
                                    <span className=' text-msg-footer fw-bold '>
                                        {orderNumber} 
                                    </span>
                                </h2>

                            </div>

                            <div className="my-4 d-flex align-items-center justify-content-center border-vending-secondary py-4 position-relative">


                                <QRCode value={routeQr} size={qrSize} bgColor="#282c34" fgColor="#fff" level="H" />

                                <div className='position-absolute top-0 start-50 translate-middle border-vending-secondary px-4 bg-white'>
                                    <p className='fw-bold text-dark p-0 m-0'>Código QR</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between gap-2">

                                <div className=' py-2 border-vending-secondary text-center w-100'>
                                    <p className='  text-msg-footer text-dark'>Total de la orden:
                                        <span className=' fw-bold text-msg-footer text-dark text-uppercase d-block'>

                                            {total.toFixed(2)} 
                                        </span>
                                    </p>
                                </div>
                                <div className=' py-2 border-vending-secondary text-center w-100'>
                                    <p className=' text-msg-footer text-dark'>Metodo de pago:
                                        <span className='fw-bold text-msg-footer text-dark text-uppercas d-block'>
                                              {dataInfoPaymentSelect}  
                                        </span>
                                    </p>

                                </div>
                            </div>
                            <div className=' mt-2'>
                                <RenderIf isTrue={voucher_Order != "0"}>
                                    <h5 className='text-center text-msg-footer fw-bold'>Recibo de pago:</h5>
                                    <div className='mt-2 d-flex alight-item-center justify-content-center'>
                                        <VendingModalVoucher showModalvoucher={showModalvoucher} setshowModalvoucher={setshowModalvoucher} voucher_Order={voucher_Order}></VendingModalVoucher>
                                    </div>
                                </RenderIf>
                                <div className="m-3 d-flex align-items-center justify-content-center ">
                                    <button className='border-vending-primary p-3 fw-bold' onClick={() => newOrder()}>Nueva orden</button>
                                </div>

                                <input id="inputConfirmation" className='vending-input-qr' ref={inputConfirmation} inputMode="none" type="text" onChange={(e) => handlercahge(e.target.value)} autoFocus></input>

                            </div>
                        </div> </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default VendingModalConfirmation