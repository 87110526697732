import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { RenderIf } from '../../components/utils/RenderIf';
import { Button } from 'react-bootstrap'
import { Card } from 'react-bootstrap';
import { DivicesFechtAPI } from '../../api/Divices';
import { WhatsappFetchAPI } from '../../api/Whatsapp';
import { MachinesFetchAPI } from '../../api/Machines';
import VendingModalKeyCode from './VendingModalKeyCode';
import img_dispensed from './img/delivery.gif'
import img_Error from './img/error.gif'
import img_support from './img/support.gif'
import ModalVendingPause from './ModalVendingPause';
import { VendingSnakkyFetchAPI } from '../../api/LocalRequest/VendingSnakky';
import oledComunication from './OledComunication';


import Swal from 'sweetalert2';

function VendingModalSupport({ showModalSupport, setShowModalSupport, contentSupport, setEventHandlerMenu, suportReturn, inputRefSupport, setAtivityTimer }) {
  let token = localStorage.getItem('x-access-token');
  let machine = JSON.parse(localStorage.getItem('machine_vending'));
  let business = localStorage.getItem('business_vending')
  let branchoffice = localStorage.getItem('branchoffice_vending')


  const [showModalVendingPause, setShowModalVendingPause] = useState(false)
  const [stringType, setStringType] = useState("")
  const [eventHandlerKey, setEventHandlerKey] = useState(0)
  const buttons_key = [
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"
  ];

  const sumString = (key) => {

    let newString = stringType + key
    console.log(newString);
    setStringType(newString)
    setEventHandlerKey(1)

  }

  const pause = () => {
    setShowModalSupport(false)
    setShowModalVendingPause(true)

    setTimeout(() => {
      localStorage.setItem('countNotification', 0);
      console.log("maquina restaurada");
      setShowModalVendingPause(false)
      refresh();
    }, 60000);
  }
  const refresh = () => {
    if (suportReturn == true) {
      window.location.reload(true);
    }
  }

  const sendNotification = async () => {
    let countNotification = 0

    if (localStorage.getItem('countNotification') == "" || localStorage.getItem('countNotification') == undefined || localStorage.getItem('countNotification') == null) {
      localStorage.setItem('countNotification', 0)
    } else {
      countNotification = parseInt(localStorage.getItem('countNotification'));
      let arrayString = stringType.split("")
      if (countNotification > 2 || arrayString.length > 8 || arrayString.length < 8 || arrayString[0] != 6) {
        console.log("la maquina estara deshabilidata por unos segundos");
        // restore machine
        pause();
      } else {
        let newValue = (countNotification + 1);
        localStorage.setItem('countNotification', newValue);


        LoadingAlert("Enviando mensaje")
        branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
        business = JSON.parse(localStorage.getItem('business_vending'))
        console.log(contentSupport);

        console.log(branchoffice.branchoffice_support_phone);
        let dataSupport = {
          "notification_type": "smartpay_notification",
          "branchoffice_id": branchoffice.id,
          "business_id": business.id,
          "phone_to_send": branchoffice.branchoffice_support_phone,
          "header_text": contentSupport.title,
          "message_body": [{ "text": contentSupport.body[0].text }, { "text": contentSupport.body[1].text + " Contacto del cliente: " + stringType }]
        }

        let dataClient = {
          "notification_type": "smartpay_notification",
          "branchoffice_id": branchoffice.id,
          "business_id": business.id,
          "phone_to_send": "507" + stringType,
          "header_text": "Solicitud de soporte recibida",
          "message_body": [{ "text": "maquina vending" }, { "text": "ayuda nos pondremos en contacto con usted para resolver lo mas pronto posible" }]
        }

        //send message to the support
        sendMessage(dataSupport, 1)

        //send message to the client
        setTimeout(() => {
          sendMessage(dataClient, 2)
        }, 5000);
      }
    }

  }

  const sendMessage = async (data, turn) => {
    try {
      const response = await WhatsappFetchAPI.sendNotificationWhatsapp(data, token)
      console.log(response);
      if (turn == 2) {
        setEventHandlerMenu(1)
        setShowModalSupport(false)
        ErrorAlert("Solicitud de soporte enviada", "Envio exitoso", "success")
        refresh();
      }

    } catch (err) {
      ErrorAlert("Opps..", "No se pudo enviar el mensaje", "error")
      console.log(err);
    }
  }


  const [step, setStep] = useState(0)

  const handleChangeSupportInput = (txt) => {
    setAtivityTimer(5)
    console.log(txt);
    switch (step) {
      case 0:


        console.log(txt);
        let arrayTxt = txt.split("")
        
        if (arrayTxt.length == 8) {
          clearStatussentorandlimitDigit(1, "2,1,4,40,20,")
          setStringType(txt)
          oledNext(txt)
          setStep(1)
          inputRefSupport.current.value = ""
        } else if (arrayTxt.length > 8) {
          clearStatussentorandlimitDigit(8, "2,1,2,15,30,")
          oledInputPhone()
        }


        //}
        //inputRefSupport.current.value = ""
        break;
      case 1:

        if (txt == "0") {
          sendNotification()
          oledSupportResponse("NUMERO GUARDADO","Pronto sera contactado") 
          //setStringType("")
        } else if (txt == "1") {
          setStep(0)
          setStringType("")
          clearStatussentorandlimitDigit(8, "2,1,2,15,30,")
          oledInputPhone()
          inputRefSupport.current.value = ""
        } else {
          refresh()
          oledSupportResponse("SOPORTE CANCELADO"," ") 
        }
        break;

    }
    //inputRefSupport.current.focus()
  }

  const oledSelccion = (txt) => {
    if (machine.machine_subtype_code === "VGG") {
      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0, '2,1,1,25,20, Tu numero es:' + txt)
      }, 50);

      setTimeout(() => {
        oledComunication(0, '2,1,1,35,30,E.Borrar.  C.Abandonar')
      }, 100);
    }
  }

  const oledNext = (txt) => {
    if (machine.machine_subtype_code === "VGG") {
      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0,  "2,1,1,20,10, Tu numero es:")
      }, 50);

      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0,   "2,1,1,30,20, " + txt)
      }, 100);

      setTimeout(() => {
        oledComunication(0, '2,1,1,5,40,0.Continuar 1.Borrar')
      }, 150);

      setTimeout(() => {
        oledComunication(0, "2,1,1,30,50,2.Abndonar")
      }, 200);
    }
  }

  const oledInputPhone = () => {
    if (machine.machine_subtype_code === "VGG") {
      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0, "2,1,1,10,10,Ingresa tu numero")
      }, 50);

      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0, "2,1,1,30,20,de celular")
      }, 100);
    }
  }

  const oledSupportResponse = (txt1, txt2) => {
    if (machine.machine_subtype_code === "VGG") {
      oledComunication(0, '2,0')
      setTimeout(() => {
        oledComunication(0, '2,1,1,10,30,'+txt1)
      }, 50);

      setTimeout(() => {
        oledComunication(0, '2,1,1,5,40,'+txt2)
      }, 100);

      
    }
  }

  const clearStatussentorandlimitDigit = async (data, settingtxt) => {
    try {

      await VendingSnakkyFetchAPI.clearData({ "limit": data, "setting":settingtxt })

    } catch (err) {
      console.log(err);
    }
  }


  //Hook to load the initial info
  useEffect(() => {
    setEventHandlerKey(0)



  }, [eventHandlerKey]);

  useEffect(() => {
    if (showModalSupport == true) {
      
      const intervalSupport =setInterval(() => {
        inputRefSupport.current.focus()
      }, 2000);


      return () => clearInterval(intervalSupport);
    }

    
  }, [showModalSupport])



  return (
    <>
      <Modal
        show={showModalSupport}
        size='lg'
        centered
        onHide={() => setShowModalSupport(false)}
      >
        <Modal.Body>
          <div className='d-flex alight-item-center justify-content-center' onClick={() => (inputRefSupport.current.focus(), setAtivityTimer(5))}>
            <section className='mx-2'>
              <div className='d-flex justify-content-center m-0'>
                <img className='help-img' src={img_support} alt='Help Img' />
              </div>

              <article>
                <div className='my-1'>
                  <h2 className='text-center text-title-help fw-bold'>Por favor, ingrese un número de contacto</h2>
                </div>

                <div className=' vendindg-message text-primary bg-primary-subtle border-primary border p-1 rounded-2'>
                  <span className='text-center fw-bold'>
                    <i class="uil uil-info-circle mx-1"></i>
                    Nuestro servicio técnico lo contactará para solucionar su problema.
                  </span>
                </div>
                <div>


                  <div className='my-lg-5 my-2'>
                    <div className="border py-2 mt-2 rounded-2">
                      <h2 className='text-center  fw-bold py-0 text-number'>
                        {stringType == "" ? <>6000-0000</> : <>{stringType}</>}
                      </h2>
                    </div>

                    <div className='row'>
                      {buttons_key.map((button) => (
                        <div className='col-4  mt-4'>
                          <div className='border border-sp border-3  shadow-sm text-center py-2' onClick={() => (sumString(button))}>
                            <span className='num-key-pad fw-bold'>{button}</span>
                          </div>
                        </div>
                      ))}
                      <div className='col-8 mt-4'>
                        <div className='border border-sp border-3 shadow-sm text-center py-2' onClick={() => (setStringType(""), setEventHandlerKey(1))} >
                          <span className='num-key-pad fw-bold'><i className="uil uil-backspace"></i> Borrar</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between my-2 rounded-3 '>
                    <button className=' m-1 btn-return-vending  rounded-3 px-5 pulse'
                      onClick={async () => (setShowModalSupport(false), setStringType(""), refresh())} >
                      <i className="uil uil-times-circle"></i>  Cerrar
                    </button>
                    <button className='m-1 btn-vending-success rounded-3 px-5'
                      onClick={async () => (sendNotification(), setStringType(""))} >
                      <i className="uil uil-message"></i> Enviar
                    </button>
                  </div>
                </div>
              </article>
            </section>
          </div>

          <input id="inputSupportlr" className='vending-input-qr' ref={inputRefSupport} inputMode="none" type="text" onChange={(e) => handleChangeSupportInput(e.target.value)} autoFocus></input>

        </Modal.Body>


      </Modal>

      <ModalVendingPause showModalVendingPause={showModalVendingPause} setShowModalVendingPause={setShowModalVendingPause}></ModalVendingPause>

    </>
  )
}

export default VendingModalSupport